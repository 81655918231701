import { DatePicker, Form, Input, Modal, Select } from "antd";
import { CreateTeacherDto } from "../../types/teacher";
import { injectIntl } from "react-intl";
import {
  DATE_FORMAT,
  DATE_REQUEST_FORMAT,
  handleInputUppercase,
} from "../utils";
import { Moment } from "moment";

type CreateTeacherProps = {
  visible: boolean;
  onCancel: () => void;
  onCreate: (values: CreateTeacherDto) => void;
  schools: { id: number; name: string }[];
  intl: any;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const CreateTeacherForm: React.FC<CreateTeacherProps> = ({
  visible,
  onCancel,
  onCreate,
  schools,
  intl,
}) => {
  const [form] = Form.useForm();

  const handleCreateTeacher = () => {
    form
      .validateFields()
      .then((values) => {
        const dob: Moment = values.dob;
        const dobStr = dob.format(DATE_REQUEST_FORMAT);

        onCreate({ ...values, dob: dobStr });
        form.resetFields();
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: "create.new.teacher",
      })}
      open={visible}
      okText={intl.formatMessage({
        id: "create",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
      })}
      onCancel={onCancel}
      onOk={handleCreateTeacher}
      width={800}
    >
      <Form
        {...layout}
        labelCol={{ flex: "200px" }}
        labelWrap
        form={form}
        onFinish={handleCreateTeacher}
      >
        <Form.Item
          name="firstName"
          label={intl.formatMessage({
            id: "firstName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Full name must be less than or equal to 255 characters",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={intl.formatMessage({
            id: "lastName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Full name must be less than or equal to 255 characters",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>

        <Form.Item
          name="dob"
          label={intl.formatMessage({
            id: "dob",
          })}
          rules={[{ required: true, message: "Please enter date of birth" }]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>

        <Form.Item
          name="address"
          label={intl.formatMessage({
            id: "address",
          })}
          rules={[
            {
              max: 255,
              message: "Address must be less than or equal to 255 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label={intl.formatMessage({
            id: "phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Invalid phone number format (e.g. 0987654321)",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: "email",
          })}
          rules={[{ type: "email", message: "Invalid email format" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="schoolId"
          label={intl.formatMessage({
            id: "school",
          })}
          rules={[{ required: true, message: "Please select a school" }]}
        >
          <Select
            placeholder={intl.formatMessage({
              id: "select.school",
            })}
          >
            {schools.map((school) => (
              <Select.Option key={school.id} value={school.id}>
                {school.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="realWavesReceivePercent"
          label={intl.formatMessage({
            id: "real.waves",
          })}
          rules={[
            {
              required: true,
              message: "Please input the real waves receive percent",
            },
            {
              type: "number",
              min: 0,
              max: 100,
              message: "Please enter a number between 0 and 100",
              transform: (value) => Number(value), // convert input to number before validation
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: "real.waves",
            })}
            type="number"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(CreateTeacherForm);
