import { Modal } from "antd";
import React from "react";

type BillCreateFormProps = {
  intl?: any;
  visible: boolean;
  billId?: number;
  onConfirm: (values: any) => void;
  onCancel: () => void;
  pdfUrl: any;
};

const BillPrintedForm: React.FC<BillCreateFormProps> = ({
  visible,
  intl,
  onConfirm,
  onCancel,
  pdfUrl,
}) => {
  return (
    <>
      <Modal
        open={visible}
        onCancel={onCancel}
        footer={[
          <button key="cancel" onClick={onCancel}>
            Thoát
          </button>,
          // <button key="print" onClick={onConfirm}>
          //   Print
          // </button>,
        ]}
      >
        <iframe
          title="Hoá Đơn Học Phí"
          id="pdf-iframe"
          src={pdfUrl}
          width="100%"
          height="500px"
        ></iframe>
      </Modal>
    </>
  );
};

export default BillPrintedForm;
