import React, { useEffect, useRef, useState } from "react";
import { StudentInfo } from "../../types/student";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputRef,
  Modal,
  notification,
} from "antd";
import { CreateStudentDto } from "../../types/student";
import { injectIntl } from "react-intl";
import {
  DATE_FORMAT,
  DATE_REQUEST_FORMAT,
  handleInputUppercase,
} from "../utils";
import { Moment } from "moment";

interface CreateStudentProps {
  visible: boolean;
  onCreate: (values: CreateStudentDto) => void;
  onCancel: () => void;
  intl: any;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StudentCreateForm: React.FC<CreateStudentProps> = ({
  visible,
  onCreate,
  onCancel,
  intl,
}) => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    // Focus on the input field when the form opens
    inputRef.current?.focus();
  }, []);

  const handleCreateStudent = () => {
    form
      .validateFields()
      .then((values) => {
        const dob: Moment = values.dob;
        const dobStr = dob.format(DATE_REQUEST_FORMAT);

        form.resetFields();
        onCreate({
          ...values,
          dob: dobStr,
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
        });
      })
      .catch((info) => {
        console.log(info);
      });
  };

  return (
    <Modal
      open={visible}
      title={intl.formatMessage({
        id: "create.new.student",
      })}
      okText={intl.formatMessage({
        id: "create",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
      })}
      onCancel={onCancel}
      onOk={handleCreateStudent}
    >
      <Form form={form} {...layout} labelCol={{ flex: "200px" }} labelWrap>
        <Form.Item
          name="firstName"
          label={intl.formatMessage({
            id: "firstName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Please input the student's first name",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} ref={inputRef} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={intl.formatMessage({
            id: "lastName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Please input the student's last name",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>

        <Form.Item
          name="dob"
          label={intl.formatMessage({
            id: "dob",
          })}
          rules={[
            {
              required: true,
              message: "Please input the student's date of birth.",
            },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>

        <Form.Item
          name="parentPhoneNumber"
          label={intl.formatMessage({
            id: "parent.phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Please input a valid phone number.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label={intl.formatMessage({
            id: "phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Please input a valid phone number.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="address"
          label={intl.formatMessage({
            id: "address",
          })}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: "email",
          })}
          rules={[{ type: "email", message: "Please input a valid email." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="parentFullName"
          label={intl.formatMessage({
            id: "parent.fullname",
          })}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(StudentCreateForm);
