import { AxiosResponse } from "axios";
import axiosInstance from "../config/axiosConfig";
import {
  BillInfoDetail,
  BillReportFilterDto,
  BillUpdateStatusDto,
  CreateBillDto,
  GetAllBillDto,
  SearchBillList,
} from "../types/bill";

export const getAllBill = async (): Promise<AxiosResponse<GetAllBillDto>> => {
  const response = await axiosInstance.get("/bills");
  return response;
};

export const getDetailBill = async (
  billId: number
): Promise<AxiosResponse<BillInfoDetail>> => {
  const response = await axiosInstance.get(`/bills/detail/${billId}`);
  return response;
};

export const createBill = async (
  dto: CreateBillDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/bills", dto);
  return response;
};

export const updateStatusBill = async (
  dto: BillUpdateStatusDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/bills/status", dto);
  return response;
};

export const getReportZip = async (dto: BillReportFilterDto): Promise<any> => {
  const response = await axiosInstance.post("/bills/zip", dto, {
    responseType: "blob", // Set the response type to 'blob'
  });
  return response;
};

export const getBillPrinted = async (
  billId: number
): Promise<AxiosResponse<any>> => {
  const response = await axiosInstance.get(`/bills/printed/${billId}`, {
    responseType: "blob",
  });
  // , {
  //   responseType: "blob",
  // });
  return response;
};

export const searchBill = async (dto: {
  startDate?: Date;
  endDate?: Date;
  userId?: number;
  department?: string;
  name?: string;
  limit?: number;
  offset?: number;
  // order?: string;
}): Promise<AxiosResponse<SearchBillList>> => {
  let { limit, offset } = dto;
  if (!limit) {
    limit = 100;
  }
  if (!offset) {
    offset = 0;
  }
  // if (!order || (order !== "DESC" && order !== "ASC")) {
  //   order = "ASC";
  // }
  const response = await axiosInstance.post("/bills/search", {
    ...dto,
    limit,
    offset,
  });
  return response;
};
