import { Modal, Form, Input } from "antd";
import { injectIntl } from "react-intl";

interface NewSchoolFormProps {
  intl: any;
  visible: boolean;
  onCreate: (values: { name: string }) => void;
  onCancel: () => void;
}

const SchoolCreateForm: React.FC<NewSchoolFormProps> = ({
  visible,
  intl,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      open={visible}
      title={intl.formatMessage({
        id: "create.new.school",
        defaultMessage: "Create New School",
      })}
      okText={intl.formatMessage({
        id: "create",
        defaultMessage: "Create",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
        defaultMessage: "Cancel",
      })}
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label={intl.formatMessage({
            id: "school.name",
            defaultMessage: "School Name",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "school.name.required",
                defaultMessage: "Please input the name of the school!",
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(SchoolCreateForm);
