import { AxiosResponse } from "axios";
import axiosInstance from "../config/axiosConfig";
import {
  CreateStudentDto,
  GetAllStudentDto,
  ListSearchStudent,
  StudentInfoDetail,
  StudentTuitionCreateDto,
  StudentTuitionInfo,
  UpdateStudentDto,
} from "../types/student";

export const getAllStudent = async (): Promise<
  AxiosResponse<GetAllStudentDto>
> => {
  const response = await axiosInstance.get("/students");
  return response;
};

export const deleteStudent = async (studentId: number): Promise<any> => {
  const response = await axiosInstance.delete(`/students/${studentId}`);
  return response;
};

export const getStudentInfoDetail = async (
  studentId: number
): Promise<AxiosResponse<StudentInfoDetail>> => {
  const response = await axiosInstance.get(`/students/detail/${studentId}`);
  return response;
};

export const getStudentTuition = async (
  studentId: number
): Promise<AxiosResponse<StudentTuitionInfo>> => {
  const response = await axiosInstance.get(`/students/${studentId}/tuition`);
  return response;
};

export const createStudentTuition = async (
  studentId: number,
  dto: StudentTuitionCreateDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post(
    `/students/${studentId}/tuition`,
    dto
  );
  return response;
};

export const updateStudentTuition = async (
  studentId: number,
  dto: StudentTuitionCreateDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.put(
    `/students/${studentId}/tuition`,
    dto
  );
  return response;
};

export const searchStudent = async (dto: {
  name?: string;
  limit?: number;
  offset?: number;
  order?: string;
}): Promise<AxiosResponse<ListSearchStudent>> => {
  let { limit, offset, order } = dto;
  if (!limit) {
    limit = 100;
  }
  if (!offset) {
    offset = 0;
  }
  if (!order || (order !== "DESC" && order !== "ASC")) {
    order = "ASC";
  }
  const response = await axiosInstance.post("/students/search", {
    ...dto,
    limit,
    offset,
    order,
  });
  return response;
};

export const createStudent = async (
  dto: CreateStudentDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/students", dto);
  return response;
};

export const updateStudent = async (dto: UpdateStudentDto): Promise<void> => {
  await axiosInstance.patch(`/students/${dto.id}`, dto, {
    headers: { "Content-Type": "application/merge-patch+json" },
  });
};
