import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { injectIntl } from "react-intl";
import { DepartmentEnum, TransactionType } from "../../../types/transaction";
import { useEffect, useState } from "react";
import { ColumnsType, TableProps } from "antd/lib/table";
import moment from "moment";

import { searchTeacher } from "../../../services/teacher";
import { TeacherInfo } from "../../../types/teacher";
import { getStudentTuition, searchStudent } from "../../../services/student";
import { StudentInfo, StudentTuitionDetail } from "../../../types/student";
import number2vn from "number2vn";
import { getDetailBill } from "../../../services/bill";
import {
  BillInfoDetail,
  CreateBillDto,
  TransactionStatus,
} from "../../../types/bill";
import { EducationLevel } from "../../../types/class";
import { convertFromDateResponseToDateFormat } from "../../utils";
import { useMainStore } from "../../../stores/main.store";
import { listDepartmentOption } from "../../../utils/utils";

type BillCreateFormProps = {
  intl: any;
  visible: boolean;
  isEdited: boolean;
  billId?: number;
  onConfirm: (values: any) => void;
  onCancel: () => void;
  handleDoneBill?: (value: number) => void;
  handleRejectBill?: (value: number) => void;
  handlePrintBill?: (value: number) => void;
};

interface RowData {
  id: number;
  name: string;
  amount: number;
}

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

export type RowDataItem = StudentTuitionDetail & {
  disabled: boolean;
};

const BillCreateForm: React.FC<BillCreateFormProps> = ({
  visible,
  intl,
  onConfirm,
  onCancel,
  isEdited = false,
  billId,
  handleRejectBill,
  handleDoneBill,
  handlePrintBill,
}) => {
  // State
  const [form] = Form.useForm();
  const [billType, setBillType] = useState(null);
  const [billAmount, setBillAmount] = useState<number>(0);
  const [searchTeacherName, setSearchTeacherName] = useState("");
  const [searchTeacherNameOptions, setSearchTeacherNameOptions] = useState<
    TeacherInfo[]
  >([]);

  const [selectedDepartment, setSelectedDepartment] = useState<
    DepartmentEnum | undefined
  >(undefined);
  const [teacherId, setTeacherId] = useState<number | null | undefined>(null);
  const [isInputTeacherEnable, setIsInputTeacherEnable] = useState(true);

  const [studentId, setStudentId] = useState<number | null | undefined>(null);
  const [searchStudentNameOptions, setSearchStudentNameOptions] = useState<
    StudentInfo[]
  >([]);
  const [searchStudentName, setSearchStudentName] = useState("");
  const [isInputStudentEnable, setIsInputStudentEnable] = useState(true);

  const [selectedRows, setSelectedRows] = useState<RowDataItem[]>([]);
  const [rowData, setRowData] = useState<RowDataItem[]>([]);
  const [readonlyBill, setReadonlyBill] = useState<BillInfoDetail | null>(null);

  // console.log("rowData", rowData);
  // console.log("readonlyBill", readonlyBill);
  // console.log("isEdited", isEdited);
  const billFilter = useMainStore((state) => state.billFilter);
  const changeBillFilter = useMainStore((state) => state.changeBillFilter);

  const handleOnCancel = () => {
    if (isEdited === false) {
      changeBillFilter({
        type: billType,
        department: selectedDepartment,
      });
    }
    onCancel();
  };

  useEffect(() => {
    if (isEdited === false) {
      if (billFilter?.type) {
        form.setFieldValue("type", billFilter.type);
        setBillType(billFilter.type);
      }
      // if (billFilter?.department) {
      //   form.setFieldValue("department", billFilter?.department);
      //   setSelectedDepartment(billFilter?.department);
      // }
    } else {
      setBillType(null);
      resetAll();
    }
  }, []);

  // handler
  const handleSelectStudent = (value: any) => {
    setStudentId(value);
  };

  const handleSelectTeacher = (value: any) => {
    setTeacherId(value);
  };

  const resetAll = () => {
    setBillAmount(0);
    setTeacherId(undefined);
    setSearchTeacherName("");
    setSelectedRows([]);
    setRowData([]);
    setReadonlyBill(null);
    setSearchStudentName("");
    setSearchTeacherNameOptions([]);
    setSearchStudentNameOptions([]);
    setIsInputTeacherEnable(true);
    setIsInputStudentEnable(true);
    form.resetFields();
    form.setFieldValue("type", billType);
  };

  useEffect(() => {
    if (isEdited === true) {
      if (billId) {
        getDetailBill(billId).then((res) => {
          setReadonlyBill(res.data);
        });
      }
    }
  }, [isEdited]);

  useEffect(() => {
    if (readonlyBill) {
      form.setFieldValue("type", readonlyBill.type);
      form.setFieldValue("detail", readonlyBill.detail);
      form.setFieldValue("note", readonlyBill.note);
      form.setFieldValue("department", readonlyBill.department);

      if (readonlyBill.type === TransactionType.TUITION) {
        const newRowDataItem: RowDataItem[] = readonlyBill.tuitions.map(
          (tuition) => {
            const isDisable = true;
            return { ...tuition, disabled: isDisable };
          }
        );
        setRowData(newRowDataItem);
        setSelectedRows(newRowDataItem);
      } else {
        form.setFieldValue("billAmount", readonlyBill.amount);
        setBillAmount(readonlyBill.amount);
      }

      if (readonlyBill.teacher) {
        setSearchTeacherName(readonlyBill.teacher.fullName);
        setSearchTeacherNameOptions([
          {
            id: readonlyBill.teacher.id,
            fullName: readonlyBill.teacher.fullName,
            dob: "",
            address: "",
            phoneNumber: "",
            email: "",
            realWavesReceivePercent: 0,
            school: { id: 0, name: "" },
            firstName: readonlyBill.teacher.fullName,
            lastName: "",
          },
        ]);
        form.setFieldValue("teacherId", readonlyBill.teacher.id);
      }
      if (readonlyBill.student) {
        setSearchStudentName(readonlyBill.student.fullName);
        setSearchStudentNameOptions([
          {
            id: readonlyBill.student.id,
            fullName: readonlyBill.student.fullName,
            dob: "",
            address: "",
            phoneNumber: "",
            email: "",
            parentPhoneNumber: "",
            parentFullName: "",
            firstName: readonlyBill.student.fullName,
            lastName: "",
          },
        ]);
        form.setFieldValue("studentId", readonlyBill.student.id);
      }
    }
  }, [readonlyBill]);

  useEffect(() => {
    if (billType) {
      resetAll();
      if (billFilter?.department) {
        form.setFieldValue("department", billFilter?.department);
        setSelectedDepartment(billFilter?.department);
      }
      switch (billType) {
        case TransactionType.TUITION:
          setIsInputStudentEnable(true);
          setIsInputTeacherEnable(false);
          break;
        case TransactionType.ADVANCES_TO_EMPLOYEE:
          setIsInputStudentEnable(false);
          setIsInputTeacherEnable(true);
          break;
        case TransactionType.PRIMARY_TUITION:
          setIsInputStudentEnable(false);
          setIsInputTeacherEnable(true);
          break;
        case TransactionType.OTHER_EXPENSE:
          setIsInputStudentEnable(false);
          setIsInputTeacherEnable(false);
          break;
        case TransactionType.OTHER_INCOME:
          setIsInputStudentEnable(false);
          setIsInputTeacherEnable(false);
          break;
        case TransactionType.TEACHER_PAYMENT:
          setIsInputStudentEnable(false);
          setIsInputTeacherEnable(true);
          break;
        default:
          break;
      }
    }
  }, [billType, billFilter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeacherName && !isEdited) {
        searchTeacher({ name: searchTeacherName })
          .then((res) => {
            setSearchTeacherNameOptions(res.data.items);
          })
          .catch((err) => {});
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTeacherName]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchStudentName && !isEdited) {
        searchStudent({ name: searchStudentName })
          .then((res) => {
            // console.log("resss", res);
            setSearchStudentNameOptions(res.data.items);
          })
          .catch((err) => {});
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchStudentName]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const submitCreateBill: CreateBillDto = {
          type: values.type,
          studentId: values.studentId,
          teacherId: values.teacherId,
          tuitionIds: selectedRows.map((tuition) => tuition.id),
          detail: values.detail,
          note: values.note,
          amount:
            values.type !== TransactionType.TUITION ? billAmount : undefined,
          department: values.department,
        };
        onConfirm(submitCreateBill);
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  const columns: ColumnsType<RowDataItem> = [
    { title: "Lớp", dataIndex: "className", ellipsis: true },
    { title: "Giáo Viên", dataIndex: "teacherName", ellipsis: true },
    { title: "Tháng", dataIndex: "month", width: "50px", ellipsis: true },
    { title: "Năm", dataIndex: "year", width: "50px", ellipsis: true },

    {
      title: "Học Phí",
      dataIndex: "amount",
      ellipsis: true,
      render: (amount, record, index) => {
        return `${amount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`;
      },
    },
  ];

  useEffect(() => {
    if (studentId) {
      const curYear = +moment().format("Y");
      getStudentTuition(studentId).then((res) => {
        if (res.data.unpaidTuiTion) {
          if (selectedDepartment) {
            setRowData(
              res.data.unpaidTuiTion
                .filter((tuition) => tuition.department === selectedDepartment)
                .map((tuition) => {
                  const nextMonth = +moment().add(1, "months").format("M");
                  let nextYear = +moment().format("Y");
                  if (nextMonth === 1) nextYear++;
                  let isDisable2 = true;
                  if (curYear < nextYear) {
                    isDisable2 = false;
                  } else if (tuition.month < nextMonth) {
                    isDisable2 = false;
                  }
                  let isDisable = false;
                  // console.log("isDisable2", isDisable2);
                  return { ...tuition, disabled: isDisable };
                })
            );
          } else {
            setRowData([]);
          }
        }
      });
    } else {
      setRowData([]);
    }
  }, [studentId, selectedDepartment]);

  const onSelectChange = (
    selectedRowKeys: React.Key[],
    selectedRows: RowDataItem[]
  ) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
    // type: "checkbox",
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],

    getCheckboxProps: (record: RowDataItem) => ({
      // disabled: record.disabled === true ? true : false, // Column configuration not to be checked
      disabled: false,
    }),
  };

  const validateTeacher = (_: any, teacherId: number) => {
    if (
      (billType === TransactionType.ADVANCES_TO_EMPLOYEE ||
        billType === TransactionType.PRIMARY_TUITION ||
        billType === TransactionType.TEACHER_PAYMENT) &&
      !teacherId
    ) {
      return Promise.reject(new Error("Vui lòng nhập giáo viên"));
    } else {
      return Promise.resolve();
    }
  };

  const validateStudent = (_: any, studentId: number) => {
    if (billType === TransactionType.TUITION && !studentId) {
      return Promise.reject(new Error("Vui lòng nhập học sinh"));
    } else {
      return Promise.resolve();
    }
  };

  const validateAmount = (_: any, value: string) => {
    if (billType === TransactionType.TUITION && value === "0 VND") {
      return Promise.reject(new Error("Số tiền phải lớn hơn 0"));
    } else {
      return Promise.resolve();
    }
  };

  const validateDetail = (_: any, detail: string) => {
    if (
      (billType === TransactionType.OTHER_EXPENSE ||
        billType === TransactionType.OTHER_INCOME) &&
      (!detail || detail.trim() === "")
    ) {
      return Promise.reject(new Error("Vui lòng nhập nội dung hoá đơn"));
    } else {
      return Promise.resolve();
    }
  };

  const validateBillAmount = (_: any, billAmount: number) => {
    // console.log({ billAmount });
    if (
      billType !== TransactionType.TUITION &&
      (!billAmount || billAmount === 0)
    ) {
      return Promise.reject(new Error("Vui lòng nhập số tiền"));
    } else {
      return Promise.resolve();
    }
  };

  const calculateTotal = () => {
    const total = selectedRows.reduce((acc, cur) => acc + cur.amount, 0);
    return `${total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`;
  };

  useEffect(() => {
    const tuition = calculateTotal();
    form.setFieldsValue({
      studentTuition: tuition,
    });
  }, [selectedRows]);

  const checkInputAmount = (amount: number) => {
    // console.log({ amount });
    if (isNaN(amount)) {
      return "không";
    }
    // if(isEdited){

    // }
    return number2vn(amount);
  };

  return (
    <Modal
      width={1000}
      title={isEdited ? "Thông Tin Hoá Đơn" : "Tạo Hoá Đơn"}
      open={visible}
      okText={intl.formatMessage({
        id: "create",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
      })}
      onCancel={handleOnCancel}
      onOk={onFinish}
      footer={isEdited ? false : undefined}
    >
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{ maxWidth: 900 }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Loại Hoá Đơn"
              name="type"
              rules={[
                { required: true, message: "Vui lòng chọn loại hoá đơn" },
              ]}
            >
              <Select
                disabled={isEdited}
                value={billType}
                allowClear
                showArrow
                showSearch
                onChange={(value) => {
                  setBillType(value);
                }}
              >
                <Option value={TransactionType.ADVANCES_TO_EMPLOYEE}>
                  Tạm ứng
                </Option>
                <Option value={TransactionType.PRIMARY_TUITION}>
                  Thu học phí tiểu học (không có ds lớp)
                </Option>
                <Option value={TransactionType.OTHER_EXPENSE}>
                  Khoảng chi khác
                </Option>
                <Option value={TransactionType.OTHER_INCOME}>
                  Khoảng thu khác
                </Option>
                <Option value={TransactionType.TEACHER_PAYMENT}>
                  Trả tiền giáo viên
                </Option>
                <Option value={TransactionType.TUITION}>Học phí</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="department"
              label={intl.formatMessage({
                id: "department",
              })}
              rules={[
                { required: true, message: "Vui lòng điền tên cơ sở" },
                {
                  pattern: new RegExp(
                    `(${DepartmentEnum.PHUOC_THANH})|(${DepartmentEnum.THAI_MY})|(${DepartmentEnum.OTHER})|(${DepartmentEnum.PHUOC_THANH_2})`
                  ),
                  message: "Please select a valid education level",
                },
              ]}
            >
              <Select
                disabled={isEdited}
                onChange={(e) => {
                  setSelectedDepartment(e);
                }}
              >
                {listDepartmentOption.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Giáo viên"
              name="teacherId"
              colon={true}
              rules={[{ validator: validateTeacher }]}
            >
              <Select
                allowClear
                disabled={isEdited || !isInputTeacherEnable}
                showSearch
                value={searchTeacherName}
                placeholder="Search..."
                showArrow={false}
                filterOption={false}
                onSearch={setSearchTeacherName}
                onClear={() => {
                  setTeacherId(null);
                }}
                onSelect={handleSelectTeacher}
                defaultActiveFirstOption={false}
              >
                {searchTeacherNameOptions.map((option: TeacherInfo) => (
                  <Option key={option.id} value={option?.id}>
                    {`${option?.firstName} ${option?.lastName}  ${
                      option.dob !== ""
                        ? "[" +
                          convertFromDateResponseToDateFormat(option.dob) +
                          "]"
                        : ""
                    }`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Học sinh"
              name="studentId"
              rules={[{ validator: validateStudent }]}
            >
              <Select
                allowClear
                disabled={isEdited || !isInputStudentEnable}
                showSearch
                value={searchStudentName}
                placeholder="Search..."
                showArrow={false}
                filterOption={false}
                onSearch={setSearchStudentName}
                onClear={() => {
                  setStudentId(null);
                }}
                onSelect={handleSelectStudent}
                defaultActiveFirstOption={false}
              >
                {searchStudentNameOptions.map((option: StudentInfo) => (
                  <Option key={option.id} value={option?.id}>
                    {`${option.firstName + " " + option.lastName} ${
                      option.dob !== ""
                        ? "[" +
                          convertFromDateResponseToDateFormat(option.dob) +
                          "]"
                        : ""
                    }`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Bảng học phí" labelCol={{ span: 3 }}>
              <Table
                columns={columns}
                dataSource={rowData}
                rowSelection={isEdited === false ? rowSelection : undefined}
                rowKey="id"
                scroll={{ y: 200 }}
                size="small"
                pagination={false}
              />
            </Form.Item>
            <Form.Item
              label="Tổng học phí được chọn"
              labelCol={{ span: 15 }}
              rules={[{ validator: validateAmount }]}
              name="studentTuition"
              // name="studentTuition"
            >
              <Input
                disabled={isEdited || !isInputStudentEnable}
                type="text"
                readOnly
              />
              {/* <Input
                disabled={!isInputStudentEnable}
                type="text"
                
                value={`${selectedRows
                  .reduce((acc, cur) => acc + cur.amount, 0)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`}
                readOnly
              /> */}
            </Form.Item>
            <Form.Item label="Viết thành chữ" labelCol={{ span: 15 }}>
              <Input
                disabled={isEdited || !isInputStudentEnable}
                type="text"
                value={`${number2vn(
                  selectedRows.reduce((acc, cur) => acc + cur.amount, 0)
                )} đồng`}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {/* <Form.Item name={["user", "url"]} label="Nội Dung HĐ"> */}
            <Form.Item
              name="detail"
              label="Nội Dung HĐ"
              rules={[{ validator: validateDetail }]}
            >
              <Input disabled={isEdited} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Số tiền"
              name="billAmount"
              rules={[{ validator: validateBillAmount }]}
            >
              <InputNumber
                prefix="VND"
                style={{ width: "100%" }}
                formatter={(value) =>
                  value
                    ? `${value
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                    : ""
                }
                disabled={isEdited || isInputStudentEnable}
                parser={(value) => {
                  // let newValue = value;
                  // if (
                  //   value &&
                  //   value?.length > 0 &&
                  //   Number.isInteger(parseInt(value[value?.length - 1], 10))
                  // ) {
                  //   newValue = value?.slice(0, value.length - 1);
                  // }

                  const valueNumber = value?.replace(/\$\s?|(,*)/g, "") || "";
                  if (!isEdited) setBillAmount(Number(valueNumber));
                  return valueNumber;
                }}
                // onChange={(e: any) => {
                //   console.log(e);
                // }}
                // width="200px"
                // step={0.001}
                // value={billAmount}
                // onChange={(e: any) => {
                //   // console.log("e.target.value", e.target.value);
                //   // form.setFieldValue("billAmount", e.target.value + ",");
                //   // setBillAmount(e.target.value * 10);
                //   // setBillAmount(e.target.value);
                // }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Viết thành chữ" labelCol={{ span: 3 }}>
              <Input
                disabled={isEdited || isInputStudentEnable}
                type="text"
                value={`${checkInputAmount(billAmount)} đồng`}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Ghi chú" name="note">
              <Input.TextArea rows={4} disabled={isEdited} />
            </Form.Item>
          </Col>
        </Row>
        {isEdited === true && (
          <Row>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Space>
                {readonlyBill?.status === TransactionStatus.DRAFT && (
                  <Button
                    type="primary"
                    onClick={() => {
                      if (handleDoneBill) {
                        handleDoneBill(readonlyBill.id);
                      }
                    }}
                  >
                    Hoàn Tất Hoá Đơn
                  </Button>
                )}
                {readonlyBill?.status === TransactionStatus.DONE && (
                  <Button
                    type="primary"
                    onClick={() => {
                      if (handlePrintBill) {
                        handlePrintBill(readonlyBill.id);
                      }
                    }}
                  >
                    Xem Hoá Đơn
                  </Button>
                )}
                {readonlyBill?.status === TransactionStatus.DONE && (
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      if (handleRejectBill) {
                        handleRejectBill(readonlyBill.id);
                      }
                    }}
                  >
                    Huỷ Hoá Đơn
                  </Button>
                )}

                <Button onClick={handleOnCancel}>Thoát</Button>
              </Space>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default injectIntl(BillCreateForm);
