import { PlusOutlined } from "@ant-design/icons";
import "../css/styles.css";
import { Button, Card, Col, Input, Row, notification } from "antd";
import { Fragment, useEffect, useState } from "react";
import SearchCard from "../components/teacher/SearchCard";
import { injectIntl } from "react-intl";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import {
  CreateTeacherDto,
  TeacherInfo,
  UpdateTeacherDto,
} from "../types/teacher";
import withAuth from "../components/auth/withAuth";
import {
  createTeacher,
  getAllTeacher,
  updateTeacher,
} from "../services/teacher";
import CreateTeacherForm from "../components/teacher/CreateTeacherForm";
import { getAllSchool } from "../services/school";
import { SchoolInfo } from "../types/school";
import TeacherEditForm from "../components/teacher/TeacherEditForm";
import moment, { Moment } from "moment";
import {
  DATE_FORMAT,
  DATE_REQUEST_FORMAT,
  convertFromDateResponseToDateFormat,
} from "../components/utils";

const Teacher = (props: any) => {
  const { intl } = props;
  const [listTeacher, setListTeacher] = useState<TeacherInfo[]>([]);
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<TeacherInfo>();
  useEffect(() => {
    handleGetAllTeacher();
  }, []);

  const handleGetAllTeacher = () => {
    getAllTeacher().then((res) => {
      setListTeacher(res.data.items || []);
    });
  };

  const [listSchool, setListSchool] = useState<SchoolInfo[]>([]);

  useEffect(() => {
    getAllSchool().then((res) => {
      const listSchool = res.data.items;
      setListSchool(listSchool || []);
    });
  }, []);

  const handleCreateTeacher = (values: CreateTeacherDto) => {
    createTeacher(values)
      .then((res) => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.create.complete",
          }),
          description: intl.formatMessage({
            id: "detail.create.teacher.successfully",
          }),
        });
        handleGetAllTeacher();
        setIsModalCreateVisible(false);
      })
      .catch((e) => {
        notification.error({
          message: intl.formatMessage({
            id: "noti.create.fail",
          }),
          description: intl.formatMessage({
            id: "detail.create.teacher.fail",
          }),
        });
      });
  };

  const handleConfirmEdit = (record: UpdateTeacherDto) => {
    updateTeacher(record)
      .then(() => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.update.complete",
          }),
          description: intl.formatMessage({
            id: "detail.update.teacher.successfully",
          }),
        });
        handleGetAllTeacher();
        setIsModalEditVisible(false);
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          notification.error({
            message: intl.formatMessage({
              id: "noti.update.fail",
            }),
            description: "Bạn không được cấp quyền này",
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: "noti.update.fail",
            }),
            description: intl.formatMessage({
              id: "detail.update.teacher.fail",
            }),
          });
        }
      });
  };

  const columns: ProColumns<TeacherInfo>[] = [
    {
      title: "ID",
      hideInTable: true,
      dataIndex: "id",
      ellipsis: true,
      key: "id",
    },
    {
      title: intl.formatMessage({ id: "fullname" }),
      // dataIndex: "fullName",
      key: "fullName",
      copyable: true,
      ellipsis: true,
      tip: `teacher's full name`,
      render: (text: any, record: TeacherInfo) => (
        <div>{`${record.firstName} ${record.lastName}`}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: "dob" }),
      dataIndex: "dob",
      ellipsis: true,
      key: "dob",
      render: (text: any, record: TeacherInfo) => {
        const date = convertFromDateResponseToDateFormat(record.dob);
        return <div>{`${date}`}</div>;
      },
    },
    {
      title: intl.formatMessage({ id: "real.waves" }),
      dataIndex: "realWavesReceivePercent",
      ellipsis: true,
      key: "realWavesReceivePercent",
    },
    {
      title: intl.formatMessage({ id: "school.name" }),
      dataIndex: ["school", "name"],
      ellipsis: true,
      key: "schoolName",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: TeacherInfo) => (
        <Button
          style={{ height: "30px", lineHeight: "30px" }}
          type="primary"
          onClick={() => {
            setSelectedTeacher(record);
            setIsModalEditVisible(true);
          }}
        >
          {intl.formatMessage({
            id: "edit",
            defaultMessage: "Edit",
          })}
        </Button>
      ),
    },
  ];

  const pagination = {
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} ${intl.formatMessage({
        id: "of",
      })} ${total} ${intl.formatMessage({ id: "records" })}`,
  };

  const handleSearch = (value: string) => {
    const uppercaseValue = value.toUpperCase();
    getAllTeacher().then((res) => {
      const listTeacher = res.data.items;
      const listNewTeacher = listTeacher.filter((teacher) => {
        const fullName = teacher.firstName + " " + teacher.lastName;
        if (fullName.includes(uppercaseValue)) {
          return true;
        }
        return false;
      });
      setListTeacher(listNewTeacher);
    });
  };

  return (
    <Fragment>
      {/* <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <SearchCard></SearchCard>
        </Col>
      </Row> */}

      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card>
            <ProTable
              headerTitle={intl.formatMessage({
                id: "teacher.list",
                defaultMessage: "teacher.list",
              })}
              dataSource={listTeacher}
              columns={columns}
              search={false}
              pagination={pagination}
              // toolbar={{
              //   search: {
              //     allowClear: true,
              //     placeholder: "Tìm tên giáo viên",
              //     onSearch: handleSearch,
              //   },
              // }}
              toolBarRender={() => [
                <Input.Search
                  className="search-input"
                  style={{ height: "40px !important" }}
                  allowClear
                  placeholder={"Tìm tên giáo viên"}
                  onSearch={handleSearch}
                />,
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsModalCreateVisible(true)}
                >
                  {intl.formatMessage({
                    id: "add",
                    defaultMessage: "Add",
                  })}
                </Button>,
              ]}
            />
          </Card>
        </Col>
      </Row>
      {isModalCreateVisible && (
        <CreateTeacherForm
          visible={isModalCreateVisible}
          onCreate={handleCreateTeacher}
          onCancel={() => setIsModalCreateVisible(false)}
          schools={listSchool}
        />
      )}
      {isModalEditVisible && (
        <TeacherEditForm
          visible={isModalEditVisible}
          value={selectedTeacher}
          onConfirm={handleConfirmEdit}
          onCancel={() => setIsModalEditVisible(false)}
          schools={listSchool}
        />
      )}
    </Fragment>
  );
};

export default withAuth(injectIntl(Teacher));
