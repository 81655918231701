import { ClassInfo } from "./class";
import { StudentInfo } from "./student";
import { TeacherInfo } from "./teacher";

export type TransactionInfo = {
  id: number;
  type: TransactionType;
  note: string;
  student: StudentInfo;
  class: ClassInfo;
  teacher: TeacherInfo;
  month: number;
  year: number;
  amount: number;
  isDebt: boolean;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: number;
};

export enum TransactionType {
  OTHER_INCOME = "OTHER_INCOME",
  OTHER_EXPENSE = "OTHER_EXPENSE",
  ADVANCES_TO_EMPLOYEE = "ADVANCES_TO_EMPLOYEE",
  TUITION = "TUITION",
  TEACHER_PAYMENT = "TEACHER_PAYMENT",
  PRIMARY_TUITION = "PRIMARY_TUITION",
}

export enum DepartmentEnum {
  PHUOC_THANH = "PHUOC_THANH",
  PHUOC_THANH_2 = "PHUOC_THANH_2",
  THAI_MY = "THAI_MY",
  OTHER = "OTHER",
}

export type GetAllTransactionInfoDto = {
  items: TransactionInfo[];
};

export type CreateTransactionDto = {
  type: TransactionType;
  note: string;
  studentId: number;
  classId: number;
  teacherId: number;
  month: number;
  year: number;
  amount: number;
  isDebt: boolean;
};
