import moment, { Moment } from "moment";

export function filterOption(input: string, option: any) {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}
export const handleInputUppercase = (e: React.FormEvent<HTMLInputElement>) => {
  const target = e.target as HTMLInputElement;
  target.value = target.value.toUpperCase();
};

export const convertFromDateResponseToDateFormat = (date: string) => {
  const momentDate: Moment = moment(date, DATE_REQUEST_FORMAT);
  return momentDate.format(DATE_FORMAT);
};

export const convertFromDateResponseToMoment = (date: string | null) => {
  if (date) return moment(date, DATE_REQUEST_FORMAT);
  return null;
};

export const DATE_FORMAT = "DD/MM/YYYY";

export const DATE_REQUEST_FORMAT = "YYYY-MM-DD";
