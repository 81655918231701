import { Modal, Form, Input, message } from "antd";
import { injectIntl } from "react-intl";
import { SchoolInfo } from "../../types/school";
import { useEffect, useState } from "react";

interface EditSchoolFormProps {
  intl: any;
  visible: boolean;
  value?: SchoolInfo;
  onConfirm: (values: SchoolInfo) => void;
  onCancel: () => void;
}

const SchoolEditForm: React.FC<EditSchoolFormProps> = ({
  visible,
  intl,
  onConfirm,
  onCancel,
  value,
}) => {
  const [form] = Form.useForm();
  const [schoolName, setSchoolName] = useState(value?.name);

  useEffect(() => {
    setSchoolName(value?.name);
    form.setFieldValue("name", value?.name);
  }, [value]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        onConfirm({ id: value?.id || -1, name: values.name });
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  return (
    <Modal
      open={visible}
      title={intl.formatMessage({
        id: "edit.school",
        defaultMessage: "Edit school",
      })}
      okText={intl.formatMessage({
        id: "edit",
        defaultMessage: "Edit",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
        defaultMessage: "Cancel",
      })}
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label={intl.formatMessage({
            id: "school.name",
            defaultMessage: "School Name",
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "school.name.required",
                defaultMessage: "Please input the name of the school!",
              }),
            },
          ]}
        >
          <Input defaultValue={schoolName} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(SchoolEditForm);
