import { Button, DatePicker, Form, Select } from "antd";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { DepartmentEnum } from "../../../types/transaction";
import { getReportZip } from "../../../services/bill";
import { saveAs } from "file-saver";
const { Option } = Select;

type BillDetailProps = {
  intl: any;
};
const BillDetail: React.FC<BillDetailProps> = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      // const dto = {
      //   // Provide the necessary data for generating the report
      // };
      const response = await getReportZip(values);

      // console.log("response", response);ww

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/zip" });

      // Save the Blob as a file using the file-saver library
      saveAs(blob, `baocao_thang${values.month}_coso_${values.department}.zip`);
      // // Create a temporary URL for the Blob
      // const url = window.URL.createObjectURL(blob);

      // // Create a link element
      // const link = document.createElement("a");
      // link.href = url;
      // link.download = `baocao_thang${values.month}_coso_${values.department}.zip`; // Set the filename for the downloaded file

      // // Trigger the click event on the link to start the download
      // link.click();

      // // Clean up the temporary URL and link
      // window.URL.revokeObjectURL(url);
      // link.remove();
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading report:", error);
      setIsLoading(false);
    }
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 6 }}
      name="billReportForm"
      onFinish={onFinish}
      initialValues={{
        department: DepartmentEnum.PHUOC_THANH,
        startDate: null,
        endDate: null,
        month: 1,
      }}
    >
      <Form.Item
        name="department"
        label="Cơ Sở"
        rules={[{ required: true, message: "Please select a department" }]}
      >
        <Select>
          <Option value={DepartmentEnum.PHUOC_THANH}>Phước Thạnh</Option>
          <Option value={DepartmentEnum.THAI_MY}>Thái Mỹ</Option>
          {/* <Option value={DepartmentEnum.OTHER}>Other</Option> */}
        </Select>
      </Form.Item>

      <Form.Item
        name="startDate"
        label="Ngày bắt đầu"
        rules={[{ required: true, message: "Please select a start date" }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="endDate"
        label="Ngày kết thúc"
        rules={[{ required: true, message: "Please select an end date" }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="month"
        label="Tháng"
        rules={[{ required: true, message: "Please select a month" }]}
      >
        <Select>
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
          <Option value={6}>6</Option>
          <Option value={7}>7</Option>
          <Option value={8}>8</Option>
          <Option value={9}>9</Option>
          <Option value={10}>10</Option>
          <Option value={11}>11</Option>
          <Option value={12}>12</Option>
          {/* Add more options for other months */}
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: "right" }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Tạo Báo Cáo
        </Button>
      </Form.Item>
    </Form>
  );
};

export default injectIntl(BillDetail);
