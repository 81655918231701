import React, { Fragment, useEffect, useRef, useState } from "react";
import "./classes.css";
import {
  Select,
  Form,
  Button,
  Card,
  Row,
  Col,
  Space,
  Modal,
  notification,
  Input,
} from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { filterOption } from "../utils";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { ClassInfo, ClassStudentDetail } from "../../types/class";
import { injectIntl } from "react-intl";
import SearchStudentModal from "./SearchStudentModal";
import { TeacherInfo } from "../../types/teacher";
import {
  addStudentToClass,
  deleteStudentFromClass,
  getDetailClass,
  updateStudentTuitionPercent,
} from "../../services/classes";
import { DepartmentName } from "../../types/enum";
import UpdateStudentTuitionPercentModal from "./UpdateStudentTuitionPercentModal";
const { confirm } = Modal;

const { Option } = Select;
type ClassDetailTableProps = {
  resetCnt: number;
  listClass: ClassInfo[];
  listTeacher: TeacherInfo[];
  intl: any;
};

const ClassDetailTable: React.FC<ClassDetailTableProps> = ({
  intl,
  listClass,
  listTeacher,
  resetCnt,
}) => {
  const [form] = Form.useForm();
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);
  const [selectedStudent, setSelectedStudent] =
    useState<ClassStudentDetail | null>(null);

  const [classSelectList, setClassSelectList] = useState<
    { id: number; name: string; teacherId: number; department: string }[]
  >([]);
  const [isBeginSearch, setIsBeginSearch] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [
    isModalEditStudentTuitionVisible,
    setIsModalEditStudentTuitionVisible,
  ] = useState(false);

  const actionRef = useRef<ActionType>();
  const [classStudentDetail, setClassStudentDetail] = useState<
    ClassStudentDetail[]
  >([]);

  const [backupClassStudentDetail, setBackupClassStudentDetail] = useState<
    ClassStudentDetail[]
  >([]);
  useEffect(() => {
    setIsBeginSearch(false);
  }, [resetCnt]);

  // console.log("classStudentDetail", classStudentDetail);
  useEffect(() => {
    if (listClass) {
      let classSelectList = listClass.map((classItem) => {
        // console.log(DepartmentName[classItem.department]);
        return {
          id: classItem.id,
          name: classItem.className,
          teacherId: classItem.teacher.id,
          department: DepartmentName[classItem.department],
        };
      });
      if (!selectedTeacherId) {
        setClassSelectList(classSelectList);
      } else {
        const newClassSelectList = classSelectList.filter(
          (classItem) => classItem.teacherId === selectedTeacherId
        );
        setClassSelectList(newClassSelectList);
      }
    }
  }, [listClass, selectedTeacherId]);

  const columns: ProColumns<ClassStudentDetail>[] = [
    {
      title: "ID",
      dataIndex: "id",
      hideInTable: true,
      ellipsis: true,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: intl.formatMessage({
        id: "student.name",
      }),
      // dataIndex: "fullName",
      render: (text: any, record: ClassStudentDetail) => (
        <div>{`${record.firstName} ${record.lastName}`}</div>
      ),
    },
    {
      title: "% Học phí hiện tại",
      dataIndex: "tuitionPercent",
      ellipsis: true,
      render: (text: any, record: ClassStudentDetail) => {
        const percent = record.tuitionPercent * 100;
        return <div>{percent !== 0 ? percent + "%" : "Miễn học phí"} </div>;
      },
    },
    {
      title: intl.formatMessage({
        id: "dob",
      }),
      dataIndex: "dob",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: "action",
      }),
      key: "action",
      render: (text: any, record: ClassStudentDetail) => (
        <Space>
          <Button
            type="text"
            className="custom-btn"
            onClick={() => {
              confirm({
                title: "Bạn có chắc chắn loại học sinh này ra khỏi lớp?",
                okText: "Có",
                cancelText: "Không",
                onOk() {
                  if (selectedClassId) {
                    deleteStudentFromClass({
                      classId: selectedClassId,
                      studentId: record.id,
                    })
                      .then(() => {
                        notification.success({
                          message: "Xoá thành công",
                          description: "Đã xoá học sinh thành công",
                        });
                        getDetailClass(selectedClassId).then((res) => {
                          setClassStudentDetail(res.data.students || []);
                          setBackupClassStudentDetail(res.data.students || []);
                        });
                      })
                      .catch((err) => {
                        notification.error({
                          message: "Xoá thất bại",
                          description: "Xoá học sinh thất bại",
                        });
                      });
                  }
                },
              });
            }}
          >
            <DeleteTwoTone
              style={{ fontSize: "15px", width: "15px", height: "15px" }}
            />
          </Button>

          <Button
            type="text"
            className="custom-btn"
            onClick={() => {
              setSelectedStudent(record);
              // setSelectedClass(record);
              setIsModalEditStudentTuitionVisible(true);
            }}
          >
            <EditTwoTone
              style={{ fontSize: "15px", width: "15px", height: "15px" }}
            />
          </Button>
        </Space>
      ),
    },
  ];

  const pagination = {
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 50,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} ${intl.formatMessage({
        id: "of",
      })} ${total} ${intl.formatMessage({ id: "records" })}`,
  };

  const handleAddStudent = (id: number) => {
    if (selectedClassId) {
      addStudentToClass({ classId: selectedClassId, studentId: id }).then(
        () => {
          setIsSearchModalVisible(false);
          getDetailClass(selectedClassId).then((res) => {
            setClassStudentDetail(res.data.students || []);
            setBackupClassStudentDetail(res.data.students || []);
          });
        }
      );
    }
  };

  const handleModifyStudentTuition = (
    id: number,
    modifiedTuitionPercent: number
  ) => {
    if (selectedClassId && selectedStudent) {
      updateStudentTuitionPercent(selectedClassId, {
        studentId: selectedStudent?.id,
        tuitionPercent: modifiedTuitionPercent,
      })
        .then(() => {
          setIsModalEditStudentTuitionVisible(false);
          getDetailClass(selectedClassId).then((res) => {
            setClassStudentDetail(res.data.students || []);
            setBackupClassStudentDetail(res.data.students || []);
          });
          notification.success({
            message: "Cập nhật thành công",
            description: "Đã cập nhật % học phí của học sinh thành công",
          });
        })
        .catch((err) => {
          notification.error({
            message: "Cập nhật thất bại",
            description: "Cập nhật trạng thái học sinh thất bại",
          });
        });
    }
  };

  useEffect(() => {
    if (selectedTeacherId && selectedClassId) {
      setIsBeginSearch(true);
      getDetailClass(selectedClassId).then((res) => {
        setClassStudentDetail(res.data.students || []);
        setBackupClassStudentDetail(res.data.students || []);
      });
    } else {
      setIsBeginSearch(false);
    }
  }, [selectedTeacherId, selectedClassId]);

  return (
    <Fragment>
      <Card className="mb-24">
        <Form form={form} layout="horizontal" className="search-form">
          <Row className="search-row">
            <Form.Item name="teacherId" style={{ marginRight: "5px" }}>
              <Select
                style={{ width: "20rem" }}
                showSearch
                optionLabelProp="label"
                filterOption={filterOption}
                allowClear
                placeholder="Nhập giáo viên"
                onClear={() => {
                  setSelectedTeacherId(null);
                  setSelectedClassId(null);
                  form.resetFields(["classId"]);
                }}
                onSelect={(teacherId: any) => {
                  setSelectedTeacherId(teacherId);
                  setSelectedClassId(null);
                  form.resetFields(["classId"]);
                }}
              >
                {listTeacher.map((teacher) => {
                  return (
                    <Option
                      key={teacher.id}
                      value={teacher.id}
                      label={teacher.firstName + " " + teacher.lastName}
                    >
                      {teacher.firstName + " " + teacher.lastName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="classId">
              <Select
                style={{ width: "20rem" }}
                showSearch
                optionLabelProp="label"
                filterOption={filterOption}
                allowClear
                placeholder="Nhập tên lớp"
                onSelect={(classId: any) => {
                  setSelectedClassId(classId);
                  form.setFieldValue("classId", classId);
                }}
                onClear={() => {
                  setSelectedClassId(null);
                  form.resetFields(["classId"]);
                }}
              >
                {classSelectList.map((classItem) => {
                  return (
                    <Option
                      key={classItem.id}
                      value={classItem.id}
                      label={classItem.name}
                    >
                      {classItem.name + ` [Cở Sở: ${classItem.department}]`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Row>
        </Form>
      </Card>
      {isBeginSearch && (
        <Card>
          <ProTable<ClassStudentDetail>
            headerTitle=""
            actionRef={actionRef}
            columns={columns}
            dataSource={classStudentDetail}
            rowKey="id"
            pagination={pagination}
            search={false}
            toolBarRender={() => [
              <Input.Search
                className="input-search"
                placeholder="Search by name"
                allowClear
                onSearch={(value) => {
                  if (value === "" || !value) {
                    setClassStudentDetail(backupClassStudentDetail);
                  } else {
                    const filteredData = classStudentDetail.filter((record) => {
                      const fullName = record.firstName + " " + record.lastName;
                      return fullName
                        .toLowerCase()
                        .includes(value.toLowerCase());
                    });
                    setClassStudentDetail(filteredData);
                  }
                }}
              />,
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsSearchModalVisible(true)}
              >
                Thêm
              </Button>,
            ]}
          />
        </Card>
      )}
      <SearchStudentModal
        existingStudent={classStudentDetail}
        visible={isSearchModalVisible}
        onClose={() => setIsSearchModalVisible(false)}
        onSubmit={handleAddStudent}
      />

      <UpdateStudentTuitionPercentModal
        selectedStudent={selectedStudent}
        visible={isModalEditStudentTuitionVisible}
        onClose={() => setIsModalEditStudentTuitionVisible(false)}
        onSubmit={handleModifyStudentTuition}
      />
    </Fragment>
  );
};

export default injectIntl(ClassDetailTable);
