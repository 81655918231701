import { Select } from "antd";
import { DepartmentEnum } from "../types/transaction";

const { Option } = Select;

export const listDepartmentOption: { value: DepartmentEnum; label: string }[] =
  [
    { value: DepartmentEnum.PHUOC_THANH, label: "Phước Thạnh" },
    { value: DepartmentEnum.PHUOC_THANH_2, label: "Phước Thạnh 2" },
    { value: DepartmentEnum.THAI_MY, label: "Thái Mỹ" },
    { value: DepartmentEnum.OTHER, label: "Khác" },
  ];

export const departmentRegex = `(${DepartmentEnum.PHUOC_THANH})|(${DepartmentEnum.THAI_MY})|(${DepartmentEnum.OTHER})|(${DepartmentEnum.PHUOC_THANH_2})`;

export const departmentValueEnums = {
  [DepartmentEnum.PHUOC_THANH]: "Phước Thạnh",
  [DepartmentEnum.PHUOC_THANH_2]: "Phước Thạnh 2",
  [DepartmentEnum.THAI_MY]: "Thái Mỹ",
  [DepartmentEnum.OTHER]: "Khác",
};

export const getDepartmentName = (departmentEnum: DepartmentEnum) => {
  switch (departmentEnum) {
    case DepartmentEnum.PHUOC_THANH:
      return "PHƯỚC THẠNH";
    case DepartmentEnum.PHUOC_THANH_2:
      return "PHƯỚC THẠNH 2";
    case DepartmentEnum.THAI_MY:
      return "THÁI MỸ";
    case DepartmentEnum.OTHER:
      return "KHÁC";
    default:
      return "";
  }
};
