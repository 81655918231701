import React, { useEffect } from "react";
import { Modal, Form, Select } from "antd";
import { injectIntl } from "react-intl";
import { ClassStudentDetail } from "../../types/class";

const { Option } = Select;
interface UpdateStudentTuitionPercentModalProps {
  visible: boolean;
  selectedStudent?: ClassStudentDetail | null;
  onClose: () => void;
  onSubmit: (
    selectedStudentId: number,
    modifyStudentTuitionPercent: number
  ) => void;
  intl: any;
}

const UpdateStudentTuitionPercentModal: React.FC<
  UpdateStudentTuitionPercentModalProps
> = ({ visible, onClose, onSubmit, selectedStudent, intl }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("tuitionPercent", selectedStudent?.tuitionPercent || 0);
  }, [form, selectedStudent]);

  const handleSearch = () => {
    const tuitionPercent = form.getFieldValue("tuitionPercent") as string;
    onSubmit(selectedStudent?.id || -1, parseFloat(tuitionPercent));
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={`Thông Tin Học Sinh ${
        selectedStudent?.firstName + " " + selectedStudent?.lastName
      }`}
      okText={"Lưu"}
      cancelText={"Thoát"}
      onOk={handleSearch}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="tuitionPercent" label={"Phần trăm đóng học phí"}>
          <Select>
            <Option value={"0"}>Miễn học phí</Option>
            <Option value={"0.25"}>25%</Option>
            <Option value={"0.5"}>50%</Option>
            <Option value={"0.75"}>75%</Option>
            <Option value={"1"}>100%</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(UpdateStudentTuitionPercentModal);
