import { Redirect } from "react-router-dom";

const withAuth = (Component) => {
  const AuthRoute = () => {
    const token = localStorage.getItem("token");
    const isAuthenticated = token !== null;
    const redirectPath = localStorage.getItem("redirectPath");
    // const store = useMainStore(); // Get the Zustand store using the hook

    // useEffect(() => {
    //   if (isAuthenticated) {
    //     const decodedToken = jwtDecode(token);
    //     store.changeUserInfo(decodedToken); // Update the userInfo in the store
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []); // Empty dependency array to run only once

    if (!isAuthenticated) {
      localStorage.setItem("redirectPath", window.location.pathname);
      return <Redirect to="/sign-in" />;
    }

    if (redirectPath) {
      localStorage.removeItem("redirectPath");
      return <Redirect to={redirectPath} />;
    }

    return <Component />;
  };

  return AuthRoute;
};

export default withAuth;
