import React, { useEffect } from "react";
import { TeacherInfo } from "../../types/teacher";
import { injectIntl } from "react-intl";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import {
  DATE_FORMAT,
  DATE_REQUEST_FORMAT,
  convertFromDateResponseToMoment,
  handleInputUppercase,
} from "../utils";
import { Moment } from "moment";

type EditTeacherFormProps = {
  intl: any;
  visible: boolean;
  value?: TeacherInfo;
  schools: { id: number; name: string }[];
  onConfirm: (values: TeacherInfo) => void;
  onCancel: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TeacherEditForm: React.FC<EditTeacherFormProps> = ({
  visible,
  intl,
  onConfirm,
  onCancel,
  value,
  schools,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("firstName", value?.firstName);
    form.setFieldValue("lastName", value?.lastName);
    form.setFieldValue(
      "dob",
      convertFromDateResponseToMoment(value?.dob || null)
    );
    form.setFieldValue("address", value?.address);
    form.setFieldValue("phoneNumber", value?.phoneNumber);
    form.setFieldValue("email", value?.email);
    form.setFieldValue(
      "realWavesReceivePercent",
      value?.realWavesReceivePercent
    );
    form.setFieldValue("schoolId", value?.school.id);
  }, [form, value]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const dob: Moment = values.dob;
        const dobStr = dob.format(DATE_REQUEST_FORMAT);
        onConfirm({ id: value?.id, ...values, dob: dobStr });
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  return (
    <Modal
      open={visible}
      title={intl.formatMessage({
        id: "edit.teacher",
      })}
      okText={intl.formatMessage({
        id: "edit",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
      })}
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Form {...layout} labelCol={{ flex: "200px" }} labelWrap form={form}>
        <Form.Item
          name="firstName"
          label={intl.formatMessage({
            id: "firstName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Full name must be less than or equal to 255 characters",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={intl.formatMessage({
            id: "lastName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Full name must be less than or equal to 255 characters",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>

        <Form.Item
          name="dob"
          label={intl.formatMessage({
            id: "dob",
          })}
          rules={[
            { required: true, message: "Please enter date of birth" },
            // {
            //   pattern: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
            //   message: "Invalid date of birth format (yyyy-mm-dd)",
            // },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>

        <Form.Item
          name="address"
          label={intl.formatMessage({
            id: "address",
          })}
          rules={[
            {
              max: 255,
              message: "Address must be less than or equal to 255 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label={intl.formatMessage({
            id: "phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Invalid phone number format (e.g. 0987654321)",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: "email",
          })}
          rules={[{ type: "email", message: "Invalid email format" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="schoolId"
          label={intl.formatMessage({
            id: "school",
          })}
          rules={[{ required: true, message: "Please select a school" }]}
        >
          <Select placeholder="Select School">
            {schools.map((school) => (
              <Select.Option key={school.id} value={school.id}>
                {school.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="realWavesReceivePercent"
          label={intl.formatMessage({
            id: "real.waves",
          })}
          rules={[
            {
              required: true,
              message: "Please input the real waves receive percent",
            },
            {
              type: "number",
              min: 0,
              max: 100,
              message: "Please enter a number between 0 and 100",
              transform: (value) => Number(value), // convert input to number before validation
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: "real.waves",
            })}
            type="number"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(TeacherEditForm);
