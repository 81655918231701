import React, { useEffect } from "react";
import { StudentInfo } from "../../types/student";
import { Button, DatePicker, Form, Input, Modal } from "antd";
import { CreateStudentDto } from "../../types/student";
import { injectIntl } from "react-intl";
import {
  DATE_FORMAT,
  DATE_REQUEST_FORMAT,
  convertFromDateResponseToMoment,
  handleInputUppercase,
} from "../utils";
import { Moment } from "moment";

interface EditStudentProps {
  intl: any;
  visible: boolean;
  value?: StudentInfo;
  onConfirm: (values: StudentInfo) => void;
  onCancel: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StudentEditForm: React.FC<EditStudentProps> = ({
  visible,
  intl,
  onConfirm,
  onCancel,
  value,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("firstName", value?.firstName);
    form.setFieldValue("lastName", value?.lastName);
    form.setFieldValue(
      "dob",
      convertFromDateResponseToMoment(value?.dob || null)
    );
    form.setFieldValue("phoneNumber", value?.phoneNumber);
    form.setFieldValue("address", value?.address);
    form.setFieldValue("email", value?.email);
    form.setFieldValue("parentPhoneNumber", value?.parentPhoneNumber);
    form.setFieldValue("parentFullName", value?.parentFullName);
  }, [form, value]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const dob: Moment = values.dob;
        const dobStr = dob.format(DATE_REQUEST_FORMAT);
        onConfirm({ id: value?.id, ...values, dob: dobStr });
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  return (
    <Modal
      open={visible}
      title={intl.formatMessage({
        id: "edit.student",
        defaultMessage: "Edit student",
      })}
      // okText={intl.formatMessage({
      //   id: "edit",
      //   defaultMessage: "Edit",
      // })}
      okText="Lưu"
      cancelText={intl.formatMessage({
        id: "cancel",
        defaultMessage: "Cancel",
      })}
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Form form={form} {...layout} labelCol={{ flex: "200px" }} labelWrap>
        <Form.Item
          name="firstName"
          label={intl.formatMessage({
            id: "firstName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Please input the student's first name",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={intl.formatMessage({
            id: "lastName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Please input the student's last name",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>
        <Form.Item
          name="dob"
          label={intl.formatMessage({
            id: "dob",
          })}
          rules={[
            {
              required: true,
              message: "Please input the student's date of birth.",
            },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>

        <Form.Item
          name="parentPhoneNumber"
          label={intl.formatMessage({
            id: "parent.phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Please input a valid phone number.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label={intl.formatMessage({
            id: "phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Please input a valid phone number.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="address"
          label={intl.formatMessage({
            id: "address",
          })}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: "email",
          })}
          rules={[{ type: "email", message: "Please input a valid email." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="parentFullName"
          label={intl.formatMessage({
            id: "parent.fullname",
          })}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(StudentEditForm);
