/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import withAuth from "./components/auth/withAuth";
import Teacher from "./pages/Teacher";
import School from "./pages/School";
import Student from "./pages/Student";
import Class from "./pages/Class";
import Transaction from "./pages/Transaction";
import Attendance from "./pages/Attendance";
import BillList from "./components/bill/listBill/BillList";
import { useMainStore } from "./stores/main.store";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

export const PATH = {
  SIGN_IN: "/sign-in",
  TEACHER: "/teacher",
  STUDENT: "/student",
  CLASS: "/class",
  TRANSACTION: "/bill",
  SCHOOL: "/school",
  ATTENDANCE: "/attendance",
};

function App() {
  const store = useMainStore();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      store.changeUserInfo(decodedToken);
      store.changeDepartments(decodedToken?.departments || []);
      // console.log(decodedToken?.departments);
    }
  }, []); // Run only once when the App component mounts

  return (
    <div className="App">
      <Switch>
        {/* <Route path="/sign-up" exact component={SignUp} /> */}
        <Route path={PATH.SIGN_IN} exact component={SignIn} />
        <Main>
          <Route exact path={PATH.TEACHER} component={Teacher} />
          <Route exact path={PATH.SCHOOL} component={withAuth(School)} />
          <Route exact path="/dashboard1" component={BillList} />
          {/* <Route exact path="/tables" component={withAuth(Tables)} /> */}
          {/* <Route exact path="/billing" component={withAuth(Billing)} /> */}
          {/* <Route exact path="/rtl" component={withAuth(Rtl)} /> */}
          {/* <Route exact path="/profileahihi" component={withAuth(Profile)} /> */}
          {/* <Route exact path="/profile" component={withAuth(Profile)} /> */}
          <Route exact path={PATH.STUDENT} component={withAuth(Student)} />
          <Route exact path={PATH.CLASS} component={withAuth(Class)} />
          <Route
            exact
            path={PATH.TRANSACTION}
            component={withAuth(Transaction)}
          />

          <Route
            exact
            path={PATH.ATTENDANCE}
            component={withAuth(Attendance)}
          />
          <Route exact path="/">
            <Redirect to={PATH.TEACHER} />
          </Route>
        </Main>
      </Switch>
    </div>
  );
}

export default App;
