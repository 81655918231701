import { IntlProvider } from "react-intl";
import App from "../../App";

import messages_en from "./../../translations/en.json";
import messages_vi from "./../../translations/vi.json";

import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import viVN from "antd/lib/locale/vi_VN";
import { useMainStore } from "../../stores/main.store";

const messages = {
  en: messages_en,
  vi: messages_vi,
};

const Wrapper = () => {
  const locale = useMainStore((state) => state.locale);

  return (
    <IntlProvider
      locale={locale}
      messages={locale === "en" ? messages.en : messages.vi}
    >
      <BrowserRouter>
        <ConfigProvider locale={locale === "en" ? enUS : viVN}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default Wrapper;
