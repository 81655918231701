// import React, { useEffect, useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import { TDocumentDefinitions } from "pdfmake/interfaces";
// import { Button } from "antd";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// const Attendance = () => {
//   const [pdf, setPdf] = useState<string | null>(null);

//   useEffect(() => {
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//   }, []);

//   const documentDefinition: TDocumentDefinitions = {
//     content: ["Hello world!"],
//   };

//   const generatePdf = () => {
//     const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
//     pdfDocGenerator.getDataUrl((dataUrl) => {
//       setPdf(dataUrl);
//     });
//   };

//   const DownLoad = () => {
//     const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
//     pdfDocGenerator.download("transaction.pdf");
//   };

//   return (
//     <div>
//       <button onClick={generatePdf}>Generate PDF</button>
//       {pdf && (
//         <Document file={{ data: pdf }} onLoadError={console.error}>
//           <Page pageNumber={1} />
//         </Document>
//       )}
//       <Button onClick={DownLoad}>Print</Button>
//     </div>
//   );
// };

// export default Attendance;

//

//

import React, { useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Button } from "antd";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { getBillPrinted } from "../services/bill";
import axios from "axios";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const schoolName = "Cơ sở Thế Kỳ";
const receiptNumber = "001";
const studentName = "Võ Anh Tuấn";
const className = "5";
const tuitionTableData = [
  [
    "Toán",
    "Cô Xuân",
    { text: "3", alignment: "center" },

    { text: "300,000 VND", alignment: "right" },
  ],
  [
    "Lý",
    "Thầy Hạ",
    { text: "2", alignment: "center" },
    { text: "300,000 VND", alignment: "right" },
  ],
  [
    "Hoá",
    "Cô Thu",
    { text: "3", alignment: "center" },

    { text: "300,000 VND", alignment: "right" },
  ],
  [
    "Văn",
    "Thầy Đông",
    { text: "3", alignment: "center" },
    { text: "300,000 VND", alignment: "right" },
  ],
  [
    "Văn",
    "Thầy Đông",
    { text: "4", alignment: "center" },
    { text: "300,000 VND", alignment: "right" },
  ],
];
const monthTuition = 5;
const totalTuition = "1,500,000 VND";

const tableLayout = {
  hLineWidth: () => 0.5,
  vLineWidth: () => 0.5,
  hLineColor: () => "#bbb",
  vLineColor: () => "#bbb",
  paddingLeft: () => 0.5,
  paddingRight: () => 0.5,
  paddingTop: () => 0.5,
  paddingBottom: () => 0.5,
};

const documentDefinition1: TDocumentDefinitions = {
  content: [
    // School name and receipt number
    // {
    //   columns: [
    //     {
    //       text: schoolName,
    //       style: "small",
    //       alignment: "left",
    //     },
    //     {
    //       text: "Phước Thạnh, Củ Chi, TPHCM",
    //       style: "small",
    //       alignment: "right",
    //     },
    //   ],
    //   margin: [0, 0],
    // },
    // {
    //   columns: [
    //     {
    //       text: `Mã biên lai:  ${receiptNumber}`,
    //       style: "small",
    //       alignment: "left",
    //     },
    //     {
    //       text: "Ngày lập: 01/01/2022",
    //       style: "small",
    //       alignment: "right",
    //     },
    //   ],
    //   margin: [0, 0],
    // },
    {
      columns: [
        {
          text: "Cơ Sở Thế Kỳ",
          style: "title",
          width: "*",
        },
      ],
      margin: [0, 10, 0, 0], // [left, top, right, bottom]
    },
    {
      columns: [
        {
          text: "===================================",
          style: "small",
          width: "*",
          alignment: "center",
        },
      ],
      margin: [0, 0, 0, 0], // [left, top, right, bottom]
    },
    {
      columns: [
        {
          text: "BIÊN LAI THU TIỀN",
          style: "h1",
          width: "*",
        },
      ],
      margin: [0, 5, 0, 0], // [left, top, right, bottom]
    },

    {
      columns: [
        {
          text: `Mã Biên Lai:  ${receiptNumber}`,
          style: "small",
          alignment: "center",
        },
      ],
      margin: [0, 0],
    },
    {
      columns: [
        {
          text: "Ngày lập: 01/01/2022",
          style: "small",
          alignment: "center",
        },
      ],
      margin: [0, 0],
    },

    // Student name and class name
    {
      text: `Họ và tên: ${studentName}`,
      style: "small",
      margin: [0, 5, 0, 0], // [left, top, right, bottom]
    },
    {
      text: `Nội dung: Thu học phí tháng ${monthTuition}`,
      style: "small",
      margin: [0, 0, 0, 0], // [left, top, right, bottom]
    },
    {
      text: `Ghi chú: Không`,
      style: "small",
      margin: [0, 0, 5, 5], // [left, top, right, bottom]
    },
    // Tuition table
    {
      style: "tableExample",
      table: {
        widths: ["20%", "30%", "20%", "30%"],
        headerRows: 1,
        body: [
          [
            { text: "Môn Học", alignment: "center" },
            { text: "Giáo Viên", alignment: "center" },
            { text: "Tháng", alignment: "center" },
            { text: "Học Phí", alignment: "center" },
          ],
          ...tuitionTableData,
          [
            "",
            "",
            { text: "Tổng cộng", alignment: "center" },
            { text: totalTuition, alignment: "right" },
          ],
        ],
      },
      layout: tableLayout,
    },
    {
      text: `Số tiền ghi bằng chữ: `,
      style: "small",
      margin: [0, 5, 5, 2], // [left, top, right, bottom]
    },
    {
      columns: [
        {
          text: "Người thu tiền",
          style: "small",
          alignment: "right",
          margin: [5, 2, 3, 0],
        },
      ],
      margin: [0, 0],
    },
    {
      columns: [
        {
          text: "(Ký, họ tên)",
          style: "small",
          alignment: "right",
          margin: [0, 0, 4, 5],
        },
      ],
      margin: [0, 0],
    },

    // Thank you message
    {
      text: "Cảm ơn bạn vì đã đồng hành cùng Thế Kỳ!",
      style: "small",
      margin: [0, 5, 0, 0],
      alignment: "center",
    },
  ],
  styles: {
    title: {
      fontSize: 4,
      bold: true,
      alignment: "center",
    },
    h1: {
      fontSize: 3,
      bold: true,
      alignment: "center",
    },

    small: {
      fontSize: 2,
    },
    tableExample: {
      fontSize: 2,
      margin: [0, 0, 0, 0],
    },
  },
  pageSize: {
    width: 80,
    height: "auto",
  },
  pageMargins: [5, 5, 5, 5],
};

const documentDefinition5: TDocumentDefinitions = {
  pageSize: {
    width: 80,
    height: 500,
  },
  pageMargins: [5, 5, 5, 5],
  content: [
    {
      text: "Supermarket",
      style: "header",
      alignment: "center",
      margin: [0, 10],
    },
    {
      columns: [
        {
          text: "Invoice No: 1234",
          style: "small",
          alignment: "left",
        },
        {
          text: "Date: 01/01/2022",
          style: "small",
          alignment: "right",
        },
      ],
      margin: [0, 10],
    },
    {
      style: "table",
      table: {
        headerRows: 1,
        widths: ["*", "auto", "auto"],
        body: [
          [
            { text: "Item", style: "tableHeader" },
            { text: "Qty", style: "tableHeader" },
            { text: "Price", style: "tableHeader" },
          ],
          [
            { text: "Milk", style: "tableCell" },
            { text: "2", style: "tableCell" },
            { text: "$2.00", style: "tableCell" },
          ],
          [
            { text: "Bread", style: "tableCell" },
            { text: "1", style: "tableCell" },
            { text: "$1.50", style: "tableCell" },
          ],
          [
            { text: "Eggs", style: "tableCell" },
            { text: "12", style: "tableCell" },
            { text: "$3.00", style: "tableCell" },
          ],
          [
            { text: "Total", colSpan: 2, style: "tableHeader" },
            {},
            { text: "$6.50", style: "tableHeader" },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0 : 1;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length ? "white" : "gray";
        },
        paddingLeft: function (i, node) {
          return i === 0 ? 0 : 4;
        },
        paddingRight: function (i, node) {
          if (node.table && node.table.widths)
            return i === node.table.widths.length - 1 ? 0 : 8;
          return 4;
        },
        paddingTop: function (i, node) {
          return 2;
        },
        paddingBottom: function (i, node) {
          return 2;
        },
      },
      margin: [0, 10],
    },
    {
      text: "Thank you for shopping with us!",
      style: "small",
      alignment: "center",
      margin: [0, 5],
    },
  ],
  styles: {
    header: {
      fontSize: 3,
      bold: true,
    },
    bold: { bold: true },
    small: {
      fontSize: 2,
    },
    tableHeader: {
      bold: true,
      fontSize: 2,
      fillColor: "#eeeeee",
      alignment: "center",
    },
    tableCell: {
      fontSize: 2,
      alignment: "center",
    },
  },
};

const styles = {
  header: {
    fontSize: 18,
    bold: true,
    alignment: "center",
    margin: [0, 0, 0, 10],
  },
  subheader: {
    fontSize: 14,
    bold: true,
    margin: [0, 10, 0, 5],
  },
  small: {
    fontSize: 8,
  },
  tableHeader: {
    bold: true,
    fontSize: 12,
    color: "black",
    fillColor: "#f2f2f2",
  },
  tableRow: {
    fontSize: 10,
    color: "black",
  },
};

const documentDefinition4: TDocumentDefinitions = {
  pageSize: { width: 80, height: 297 },
  content: [
    // Add the header
    {
      text: "Supermarket Name",
      style: "header",
    },
    // Add the subheader
    {
      text: "Bill",
      style: "subheader",
    },
    // Add the table
    {
      style: "small",
      table: {
        headerRows: 1,
        widths: ["*", "auto", "auto", "auto"],
        body: [
          [
            {
              text: "Item",
              style: "tableHeader",
            },
            {
              text: "Qty",
              style: "tableHeader",
            },
            {
              text: "Price",
              style: "tableHeader",
            },
            {
              text: "Total",
              style: "tableHeader",
            },
          ],
          [
            {
              text: "Item 1",
              style: "tableRow",
            },
            {
              text: "1",
              style: "tableRow",
            },
            {
              text: "2.50",
              style: "tableRow",
            },
            {
              text: "2.50",
              style: "tableRow",
            },
          ],
          [
            {
              text: "Item 2",
              style: "tableRow",
            },
            {
              text: "2",
              style: "tableRow",
            },
            {
              text: "1.50",
              style: "tableRow",
            },
            {
              text: "3.00",
              style: "tableRow",
            },
          ],
          [
            {
              text: "Item 3",
              style: "tableRow",
            },
            {
              text: "1",
              style: "tableRow",
            },
            {
              text: "5.00",
              style: "tableRow",
            },
            {
              text: "5.00",
              style: "tableRow",
            },
          ],
          [
            {
              text: "Total",
              colSpan: 3,
              style: "tableHeader",
            },
            {},
            {},
            {
              text: "10.50",
              style: "tableHeader",
            },
          ],
        ],
      },
      layout: "lightHorizontalLines",
    },
    // Add the footer
    {
      text: "Thank you for shopping with us!",
      style: "small",
      alignment: "center",
      margin: [0, 10],
    },
    {
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 5,
          x2: 200,
          y2: 5,
          lineWidth: 0.5,
          lineColor: "#aaaaaa",
        },
      ],
    },
  ],
};
const documentDefinition3: TDocumentDefinitions = {
  pageSize: { width: 220, height: 220 },
  content: [
    {
      columns: [
        {
          text: "Supermarket Name",
          style: "header",
        },
        {
          text: "Invoice No: 12345",
          alignment: "right",
        },
      ],
    },
    {
      columns: [
        {
          text: "Address Line 1",
          style: "subheader",
        },
        {
          text: "Date: 01/01/2022",
          alignment: "right",
        },
      ],
    },
    {
      columns: [
        {
          text: "Address Line 2",
          style: "subheader",
        },
        {
          text: "Time: 12:00 PM",
          alignment: "right",
        },
      ],
    },
    {
      table: {
        widths: ["*", "auto", "auto"],
        body: [
          [
            { text: "Item", style: "tableHeader" },
            { text: "Qty", style: "tableHeader" },
            { text: "Price", style: "tableHeader" },
          ],
          ["Item 1", "1", "$1.00"],
          ["Item 2", "2", "$2.00"],
          ["Item 3", "3", "$3.00"],
        ],
      },
    },
    {
      text: "Total: $6.00",
      style: "total",
    },
    {
      text: "Thank you for shopping with us!",
      alignment: "center",
      margin: [0, 20],
    },
  ],
  styles: {
    header: {
      fontSize: 10,
      bold: true,
      margin: [0, 0, 0, 10],
    },
    subheader: {
      fontSize: 7,
      margin: [0, 5],
    },
    tableHeader: {
      bold: true,
      fontSize: 6,
      color: "black",
      fillColor: "#EEEEEE",
    },
    total: {
      bold: true,
      margin: [0, 20, 0, 10],
      fontSize: 8,
    },
  },
  defaultStyle: {
    fontSize: 5,
    color: "black",
  },
};

const documentDefinition2: TDocumentDefinitions = {
  pageSize: {
    width: 220,
    height: 220,
  },
  pageMargins: [10, 10, 10, 20],
  content: [
    {
      text: "RECEIPT",
      alignment: "center",
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      text: "ITEMS",
      bold: true,
      margin: [0, 0, 0, 5],
    },
    {
      style: "tableExample",
      table: {
        widths: [140, 40, 40],
        body: [
          ["Item 1", "$10", "1"],
          ["Item 2", "$20", "2"],
          ["Item 3", "$15", "1"],
        ],
      },
      layout: {
        fillColor: function (i, node) {
          return i === 0 ? "#CCCCCC" : null;
        },
      },
    },
    {
      columns: [
        {
          width: "50%",
          text: "Subtotal",
        },
        {
          width: "50%",
          text: "$45.00",
          alignment: "right",
        },
      ],
      margin: [0, 10],
    },
    {
      columns: [
        {
          width: "50%",
          text: "Tax",
        },
        {
          width: "50%",
          text: "$4.50",
          alignment: "right",
        },
      ],
      margin: [0, 0],
    },
    {
      columns: [
        {
          width: "50%",
          text: "Total",
          bold: true,
        },
        {
          width: "50%",
          text: "$49.50",
          bold: true,
          alignment: "right",
        },
      ],
      margin: [0, 10, 0, 20],
    },
    {
      text: "Thank you for shopping with us!",
      alignment: "center",
    },
  ],
  styles: {
    tableExample: {
      margin: [0, 5, 0, 15],
    },
  },
  defaultStyle: {
    // font: "Helvetica",
    fontSize: 5,
  },
  footer: {
    text: "Powered by PDFMake",
    alignment: "center",
    margin: [0, 20, 0, 0],
  },
};

const Bill = () => {
  const [pdf, setPdf] = useState<string | null>(null);

  const documentDefinition: TDocumentDefinitions = {
    content: [
      {
        text: "Your Company Name",
        style: "header",
      },
      {
        text: "Bill",
        style: "subheader",
      },
      // Add your bill content here
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
    },
  };

  const generatePdf = () => {
    // Define the PDF document definition

    // Create a PDF document using pdfmake
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition1);

    // Get the base64 encoded string of the PDF data
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setPdf(dataUrl);
    });
  };

  const downloadPdf = () => {
    // Define the PDF document definition

    // Create a PDF document using pdfmake
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition1);

    // Download the PDF file
    pdfDocGenerator.download("bill.pdf");
  };

  const printPdf = () => {
    // Define the PDF document definition

    // Create a PDF document using pdfmake
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition1);

    // Get the PDF data URL
    pdfDocGenerator.getDataUrl((dataUrl) => {
      // Create a new window to open the PDF document
      const printWindow = window.open("", "Print");
      if (printWindow) {
        printWindow.document.write(
          `<iframe src="${dataUrl}" width="100%" height="100%"></iframe>`
        );
        //   printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        // printWindow.close();
      } else {
        console.error("Failed to open print window.");
      }
    });
  };

  const [pdfUrl, setPdfUrl] = useState("");
  const generatePdf2 = () => {
    // Send a request to the backend to generate the PDF
    getBillPrinted(17)
      .then((response) => {
        // console.log("response", response);
        // const blob = new Blob([response.data], { type: "application/pdf" });
        // const url = URL.createObjectURL(blob);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        setPdfUrl(url);
      })
      .catch((error) => {
        console.error(error);
      });

    // fetch("http://localhost:3333/bills/printed")
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = URL.createObjectURL(blob);
    //     window.open(url, "_blank");
    //   });
  };

  const handleDownload = async () => {
    const response = await axios.get("http://localhost:3333/bills/excel", {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "example.xlsx");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      Tính năng đang phát triển ....
      {/* <Button onClick={generatePdf}>Generate PDF</Button>
      {pdf && (
        <iframe
          src={pdf}
          title="PDF Viewer"
          width="100%"
          height="500px"
        ></iframe>
      )}
      <Button onClick={downloadPdf}>Download PDF</Button>
      <Button onClick={printPdf}>Print PDF</Button>
      <div>
        <button onClick={generatePdf2}>Generate PDF2</button>
        {pdfUrl && <iframe src={pdfUrl} width="100%" height="500px" />}
      </div>
      <button onClick={handleDownload}>Download Excel File</button> */}
    </div>
  );
};

export default Bill;
