import { AxiosResponse } from "axios";
import axiosInstance from "../config/axiosConfig";
import { GetAllSchoolDto } from "../types/school";

export const getAllSchool = async (): Promise<
  AxiosResponse<GetAllSchoolDto>
> => {
  const response = await axiosInstance.get("/schools");
  return response;
};

export const createSchool = async (
  name: string
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/schools", { name });
  return response;
};

export const updateSchool = async (dto: {
  id: number;
  name: string;
}): Promise<void> => {
  await axiosInstance.patch(
    `/schools/${dto.id}`,
    {
      name: dto.name,
    },
    { headers: { "Content-Type": "application/merge-patch+json" } }
  );
};
