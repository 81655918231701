import { AxiosResponse } from "axios";
import axiosInstance from "../config/axiosConfig";
import {
  ClassInfo,
  CreateClassDto,
  GetAllClassDto,
  UpdateClassDto,
} from "../types/class";

export const getAllClass = async (): Promise<AxiosResponse<GetAllClassDto>> => {
  const response = await axiosInstance.get("/classes");
  return response;
};

export const getDetailClass = async (
  classId: number
): Promise<AxiosResponse<ClassInfo>> => {
  const response = await axiosInstance.get(`/classes/detail/${classId}`);
  return response;
};

export const addStudentToClass = async (dto: {
  classId: number;
  studentId: number;
}): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/classes/students", {
    ...dto,
    isFree: false,
  });
  return response;
};

export const updateStudentTuitionPercent = async (
  classId: number,
  dto: {
    studentId: number;
    tuitionPercent: number;
  }
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post(
    `/classes/tuitionPercent/${classId}`,
    dto
  );
  return response;
};

export const deleteStudentFromClass = async (dto: {
  classId: number;
  studentId: number;
}): Promise<void> => {
  return await axiosInstance.delete(
    `/classes/${dto.classId}/students/${dto.studentId}`
  );
};

export const createClass = async (
  dto: CreateClassDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/classes", dto);
  return response;
};

export const updateClass = async (dto: UpdateClassDto): Promise<void> => {
  await axiosInstance.patch(`/classes/${dto.id}`, dto, {
    headers: { "Content-Type": "application/merge-patch+json" },
  });
};
