import React from "react";
import { CreateClassDto, EducationLevel } from "../../types/class";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { injectIntl } from "react-intl";
import { DepartmentEnum } from "../../types/transaction";
import { filterOption } from "../utils";
import { listDepartmentOption } from "../../utils/utils";

interface CreateClassProps {
  visible: boolean;
  onCreate: (values: CreateClassDto) => void;
  onCancel: () => void;
  teachers: { id: number; name: string }[];
  intl: any;
}

const { Option } = Select;

const layout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 16 },
};

const ClassCreateForm: React.FC<CreateClassProps> = ({
  visible,
  onCreate,
  onCancel,
  teachers,
  intl,
}) => {
  const [form] = Form.useForm();
  const handleCreateClass = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  return (
    <Modal
      open={visible}
      title="Create Class"
      okText={intl.formatMessage({
        id: "create",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
      })}
      onCancel={onCancel}
      onOk={handleCreateClass}
    >
      <Form form={form} {...layout} labelCol={{ flex: "150px" }} labelWrap>
        <Form.Item
          name="grade"
          label={intl.formatMessage({
            id: "grade",
          })}
          rules={[
            {
              required: true,
              message: "Please enter the grade of the class",
            },
            {
              type: "number",
              min: 1,
              max: 12,
              message: "Please enter a number between 1 and 12",
              transform: (value) => Number(value), // convert input to number before validation
            },
          ]}
        >
          <Input type="number" placeholder="1-12" />
        </Form.Item>

        <Form.Item
          name="className"
          label={intl.formatMessage({
            id: "className",
          })}
          rules={[
            {
              required: true,
              message: "Please enter the name of the class",
            },
            {
              max: 50,
              message: "Class name must be less than 50 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="educationLevel"
          label={intl.formatMessage({
            id: "educationLevel",
          })}
          rules={[
            { required: true, message: "Please select the education level" },
            {
              pattern: new RegExp(
                `(${EducationLevel.HIGH})|(${EducationLevel.OTHER})|(${EducationLevel.PRIMARY})|(${EducationLevel.SECONDARY})`
              ),
              message: "Please select a valid education level",
            },
          ]}
        >
          <Select>
            <Option value={EducationLevel.PRIMARY}>
              {intl.formatMessage({
                id: "school.primary",
              })}
            </Option>
            <Option value={EducationLevel.SECONDARY}>
              {intl.formatMessage({
                id: "school.secondary",
              })}
            </Option>
            <Option value={EducationLevel.HIGH}>
              {intl.formatMessage({
                id: "school.high",
              })}
            </Option>
            <Option value={EducationLevel.OTHER}>
              {intl.formatMessage({
                id: "school.other",
              })}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="department"
          label={intl.formatMessage({
            id: "department",
          })}
          rules={[
            { required: true, message: "Vui lòng điền tên cơ sở" },
            {
              pattern: new RegExp(
                `(${DepartmentEnum.PHUOC_THANH})|(${DepartmentEnum.THAI_MY})|(${DepartmentEnum.OTHER})|(${DepartmentEnum.PHUOC_THANH_2})`
              ),
              message: "Please select a valid education level",
            },
          ]}
        >
          <Select>
            {listDepartmentOption.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="teacherId"
          label={intl.formatMessage({
            id: "teacher",
          })}
          rules={[
            { required: true, message: "Vui lòng điền tên giáo viên" },
            {
              type: "number",
              message: "Teacher ID must be a number",
            },
          ]}
        >
          <Select
            placeholder="Select teacher"
            allowClear
            showSearch
            filterOption={filterOption}
          >
            {teachers.map((teacher) => (
              <Select.Option
                key={teacher.id}
                value={teacher.id}
                label={teacher.name}
              >
                {teacher.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="tuition"
          label={intl.formatMessage({
            id: "tuition",
          })}
          rules={[
            {
              required: true,
              message: "Please enter the tuition amount",
            },
            {
              type: "number",
              message: "Tuition must be a number",
              transform: (value) => Number(value), // convert input to number before validation
            },
          ]}
        >
          <InputNumber
            placeholder={intl.formatMessage({
              id: "enter.tuition",
            })}
            style={{ width: "100%" }}
            formatter={(value) =>
              value
                ? `${value
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                : ""
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "") || ""}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(ClassCreateForm);
