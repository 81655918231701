import React, { useEffect, useState } from "react";
import {
  StudentTuitionCreateDto,
  StudentTuitionDetail,
} from "../../types/student";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  notification,
} from "antd";
import { injectIntl } from "react-intl";
import { filterOption } from "../utils";

import {
  createStudentTuition,
  updateStudentTuition,
} from "../../services/student";
import { ClassInfo } from "../../types/class";
import moment from "moment";
import { TeacherInfo } from "../../types/teacher";
import { DepartmentName } from "../../types/enum";

const { confirm } = Modal;
const { Option } = Select;

interface StudentCreateTuitionModalProps {
  intl: any;
  visible: boolean;
  listClass: ClassInfo[];
  listTeacher: TeacherInfo[];
  isEdited: boolean;
  selectedTuition?: StudentTuitionDetail | null;
  studentId: number;
  handleUpdateRefreshCounter?: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

const StudentCreateTuitionModal: React.FC<StudentCreateTuitionModalProps> = ({
  visible,
  intl,
  onCancel,
  listClass,
  listTeacher,
  studentId,
  isEdited,
  onSubmit,
  selectedTuition,
  handleUpdateRefreshCounter,
}) => {
  const [form] = Form.useForm<StudentTuitionCreateDto>();
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);
  const [classSelectList, setClassSelectList] = useState<
    { id: number; name: string; teacherId: number; department: string }[]
  >([]);
  const [billAmount, setBillAmount] = useState<number>(0);

  useEffect(() => {
    if (isEdited && selectedTuition) {
      form.resetFields();
      form.setFieldValue("month", selectedTuition.month);
      form.setFieldValue("year", selectedTuition.year);
      form.setFieldValue("teacherId", selectedTuition.teacherId);
      form.setFieldValue("classId", selectedTuition.classId);
      form.setFieldValue("billAmount", selectedTuition.amount);
      setBillAmount(selectedTuition.amount);
    } else {
      form.resetFields();
      const yearNow = +moment().format("Y");
      form.setFieldValue("year", yearNow);
    }
  }, [isEdited, selectedTuition]);

  const validateMonth = (rule: any, value: any) => {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || intValue < 1 || intValue > 12) {
      return Promise.reject("Month must be between 1 and 12");
    }
    return Promise.resolve();
  };

  const validateBillAmount = (_: any, billAmount: number) => {
    if (billAmount === null || billAmount === undefined || billAmount < 0) {
      return Promise.reject(new Error("Vui lòng nhập số tiền"));
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (listClass) {
      let classSelectList = listClass.map((classItem) => {
        return {
          id: classItem.id,
          name: classItem.className,
          teacherId: classItem.teacher.id,
          department: DepartmentName[classItem.department],
        };
      });
      if (!selectedTeacherId) {
        setClassSelectList(classSelectList);
      } else {
        const newClassSelectList = classSelectList.filter(
          (classItem) => classItem.teacherId === selectedTeacherId
        );
        setClassSelectList(newClassSelectList);
      }
    }
  }, [listClass, selectedTeacherId]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOk = () => {
    form.validateFields().then((values: StudentTuitionCreateDto) => {
      confirm({
        title:
          "Bạn có chắc chắn không, hành động này sẽ không được chỉnh sửa sau khi thay đổi",
        okText: "Có",
        cancelText: "Không",
        onOk() {
          if (studentId !== -1) {
            if (isEdited) {
              updateStudentTuition(studentId, {
                classId: values.classId,
                month: +values?.month,
                year: +values?.year,
                amount: billAmount,
              })
                .then(() => {
                  notification.success({
                    message: "Tạo thành công",
                    description: "Tạo thành công",
                  });
                  if (handleUpdateRefreshCounter) {
                    handleUpdateRefreshCounter();
                  }
                  form.resetFields();
                  onSubmit();
                })
                .catch((err) => {
                  notification.error({
                    message: "Tạo thất bại",
                    description: "Tạo thất bại",
                  });
                });
            } else {
              createStudentTuition(studentId, {
                classId: values.classId,
                month: +values?.month,
                year: +values?.year,
                amount: billAmount,
              })
                .then(() => {
                  notification.success({
                    message: "Cập nhật thành công",
                    description: "Cập nhật thành công",
                  });
                  if (handleUpdateRefreshCounter) {
                    handleUpdateRefreshCounter();
                  }
                  form.resetFields();
                  onSubmit();
                })
                .catch((err) => {
                  notification.error({
                    message: "Cập nhật thất bại",
                    description: "Cập nhật thất bại",
                  });
                });
            }
          }
        },
      });
    });
  };

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={isEdited ? "Sửa học phí" : "Tạo mới học phí"}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleOk}>
        <Form.Item
          name="teacherId"
          style={{ marginRight: "5px" }}
          rules={[{ required: true, message: "Vui lòng chọn giáo viên" }]}
        >
          <Select
            disabled={isEdited}
            style={{ width: "20rem" }}
            showSearch
            optionLabelProp="label"
            filterOption={filterOption}
            allowClear
            placeholder="Nhập giáo viên"
            onClear={() => {
              setSelectedTeacherId(null);
              setSelectedClassId(null);
              form.resetFields(["classId"]);
            }}
            onSelect={(teacherId: any) => {
              setSelectedTeacherId(teacherId);
              setSelectedClassId(null);
              form.resetFields(["classId"]);
            }}
          >
            {listTeacher.map((teacher) => {
              return (
                <Option
                  key={teacher.id}
                  value={teacher.id}
                  label={teacher.firstName + " " + teacher.lastName}
                >
                  {teacher.firstName + " " + teacher.lastName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="classId"
          rules={[{ required: true, message: "Vui lòng chọn tên lớp" }]}
        >
          <Select
            disabled={isEdited}
            style={{ width: "20rem" }}
            showSearch
            optionLabelProp="label"
            filterOption={filterOption}
            allowClear
            placeholder="Nhập tên lớp"
            onSelect={(classId: any) => {
              setSelectedClassId(classId);
              form.setFieldValue("classId", classId);
            }}
            onClear={() => {
              setSelectedClassId(null);
              form.resetFields(["classId"]);
            }}
          >
            {classSelectList.map((classItem) => {
              return (
                <Option
                  key={classItem.id}
                  value={classItem.id}
                  label={classItem.name}
                >
                  {classItem.name + ` [Cở Sở: ${classItem.department}]`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tháng"
          name="month"
          rules={[
            { required: true, message: "Vui lòng nhập vào tháng" },
            { validator: validateMonth },
          ]}
        >
          <Input disabled={isEdited} type="number" placeholder="Nhập tháng" />
        </Form.Item>
        <Form.Item
          label="Năm"
          name="year"
          rules={[{ required: true, message: "Vui lòng nhập vào năm" }]}
        >
          <Input disabled={isEdited} type="number" placeholder="Nhập năm" />
        </Form.Item>
        <Form.Item
          label="Số tiền"
          name="billAmount"
          rules={[{ validator: validateBillAmount }]}
        >
          <InputNumber
            prefix="VND"
            style={{ width: "100%" }}
            formatter={(value) =>
              value
                ? `${value
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                : ""
            }
            parser={(value) => {
              const valueNumber = value?.replace(/\$\s?|(,*)/g, "") || "";
              setBillAmount(Number(valueNumber));
              return valueNumber;
            }}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            {isEdited ? "Sửa" : "Tạo"}
          </Button>
          <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
            Thoát
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(StudentCreateTuitionModal);
