import { Fragment, useEffect, useRef, useState } from "react";
import "../css/styles.css";
import {
  ClassInfo,
  CreateClassDto,
  EducationLevel,
  UpdateClassDto,
} from "../types/class";
import "./../assets/styles/button.css";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { createClass, getAllClass, updateClass } from "../services/classes";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Switch,
  notification,
} from "antd";
import ClassCreateForm from "../components/class/ClassCreateForm";
import { EditTwoTone, FileTwoTone, PlusOutlined } from "@ant-design/icons";
import withAuth from "../components/auth/withAuth";
import { injectIntl } from "react-intl";
import { getAllTeacher } from "../services/teacher";
import { TeacherInfo } from "../types/teacher";
import ClassEditForm from "../components/class/ClassEditForm";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import ClassDetailTable from "../components/class/ClassDetailTable";
import { DepartmentEnum } from "../types/transaction";
import confirm from "antd/lib/modal/confirm";
import { filterOption } from "../components/utils";
import { departmentValueEnums, listDepartmentOption } from "../utils/utils";

const { Option } = Select;
const Class = (props: any) => {
  const { intl } = props;
  const [cnt, setCnt] = useState(1);
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [listClass, setListClass] = useState<ClassInfo[]>([]);
  const [listTempClass, setListTempClass] = useState<ClassInfo[]>([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentEnum | null>(null);

  const [selectedClass, setSelectedClass] = useState<ClassInfo>();
  const [listTeacher, setListTeacher] = useState<TeacherInfo[]>([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);

  const handleCreateClass = (values: CreateClassDto) => {
    createClass(values)
      .then((res) => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.create.complete",
          }),
          description: intl.formatMessage({
            id: "detail.create.class.successfully",
          }),
        });
        handleGetAllClass();
        setIsModalCreateVisible(false);
      })
      .catch((e) => {
        notification.error({
          message: intl.formatMessage({
            id: "noti.create.fail",
          }),
          description: intl.formatMessage({
            id: "detail.create.class.fail",
          }),
        });
      });
  };

  const handleConfirmEdit = (record: UpdateClassDto) => {
    updateClass(record)
      .then(() => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.update.complete",
          }),
          description: intl.formatMessage({
            id: "detail.update.class.successfully",
          }),
        });
        handleGetAllClass();
        setIsModalEditVisible(false);
      })
      .catch((e) => {
        notification.error({
          message: intl.formatMessage({
            id: "noti.update.fail",
          }),
          description: intl.formatMessage({
            id: "detail.update.class.fail",
          }),
        });
      });
  };

  const actionRef = useRef<ActionType>();
  const columns: ProColumns<ClassInfo>[] = [
    {
      title: "ID",
      hideInTable: true,
      dataIndex: "id",
      ellipsis: true,
      // valueType: "indexBorder",
      width: 48,
    },
    {
      title: intl.formatMessage({
        id: "grade",
      }),
      dataIndex: "grade",
      ellipsis: true,
      sorter: (a, b) => a.grade - b.grade,
    },
    {
      title: intl.formatMessage({
        id: "className",
      }),
      dataIndex: "className",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: "educationLevel",
      }),
      dataIndex: "educationLevel",
      ellipsis: true,
      valueEnum: {
        [EducationLevel.PRIMARY]: {
          text: intl.formatMessage({
            id: "school.primary",
          }),
        },
        [EducationLevel.SECONDARY]: {
          text: intl.formatMessage({
            id: "school.secondary",
          }),
        },
        [EducationLevel.HIGH]: {
          text: intl.formatMessage({
            id: "school.high",
          }),
        },
        [EducationLevel.OTHER]: {
          text: intl.formatMessage({
            id: "school.other",
          }),
        },
      },
    },
    {
      title: intl.formatMessage({
        id: "teacher.id",
      }),
      dataIndex: ["teacher", "id"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: "teacher.name",
      }),
      ellipsis: true,
      renderText: (text, record: ClassInfo) => {
        const teacher = record.teacher;
        return `${teacher?.firstName} ${teacher.lastName}`;
      },
      sorter: (a, b) =>
        `${a.teacher?.firstName} ${a.teacher.lastName}`.localeCompare(
          `${b.teacher?.firstName} ${b.teacher.lastName}`
        ),
    },
    {
      title: intl.formatMessage({
        id: "tuition",
      }),
      dataIndex: "tuition",
      ellipsis: true,
      renderText: (text) => {
        return `${text
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`;
      },
      width: "10%",
    },
    {
      title: intl.formatMessage({
        id: "department",
      }),
      dataIndex: "department",
      ellipsis: true,
      valueEnum: departmentValueEnums,
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: intl.formatMessage({
        id: "action",
      }),
      key: "action",
      width: "10%",
      render: (text: any, record: any) => (
        <Space>
          <Button
            type="text"
            className="custom-btn"
            onClick={() => {
              setSelectedClass(record);
              setIsModalEditVisible(true);
            }}
          >
            <EditTwoTone
              style={{ fontSize: "15px", width: "15px", height: "15px" }}
            />
          </Button>
        </Space>
      ),
    },
    {
      title: intl.formatMessage({
        id: "status",
      }),
      width: "10%",
      render: (text: any, record: ClassInfo) => (
        <Space>
          {/* <Button
            type="primary"
            danger={record.isOpen ? false : true}
            className="status-btn"
          >
            {record.isOpen
              ? intl.formatMessage({
                  id: "opened",
                })
              : intl.formatMessage({
                  id: "closed",
                })}
          </Button> */}
          <Switch
            checked={record.isOpen}
            onChange={(event) => {
              confirm({
                title: "Bạn có chắc chắn với thay đổi này?",
                okText: "Có",
                cancelText: "Không",
                onOk() {
                  handleConfirmEdit({ id: record.id, isOpen: event });
                },
                onCancel() {},
              });
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    handleGetAllClass();
    handleGetAllTeacher();
  }, []);

  const handleSearchUpdateClassesByFilter = (
    listClass: ClassInfo[],
    selectedTeacherId: number | null,
    selectedDepartment: DepartmentEnum | null
  ) => {
    let filterClasses = listClass || [];

    if (selectedTeacherId) {
      filterClasses = filterClasses.filter(
        (classroom) => classroom.teacher.id === selectedTeacherId
      );
    }
    if (selectedDepartment) {
      filterClasses = filterClasses.filter(
        (classroom) => classroom.department === selectedDepartment
      );
    }
    setListClass(filterClasses);
  };

  const handleGetAllClass = () => {
    getAllClass().then((res) => {
      const listClass = res.data.items;
      handleSearchUpdateClassesByFilter(
        listClass,
        selectedTeacherId,
        selectedDepartment
      );
      setListTempClass(listClass || []);
    });
  };
  const handleGetAllTeacher = () => {
    getAllTeacher().then((res) => {
      setListTeacher(res.data.items || []);
    });
  };

  const listClassComponent = (
    <Fragment>
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card>
            <ProTable<ClassInfo>
              actionRef={actionRef}
              columns={columns}
              dataSource={listClass}
              rowKey="id"
              search={false}
              pagination={{ pageSize: 10 }}
              toolBarRender={() => [
                <Select
                  style={{ width: "15rem" }}
                  showSearch
                  optionLabelProp="label"
                  filterOption={filterOption}
                  allowClear
                  placeholder="Tìm theo giáo viên"
                  onClear={() => {
                    setSelectedTeacherId(null);
                    setListClass(listTempClass);
                  }}
                  onSelect={(teacherId: any) => {
                    setSelectedTeacherId(teacherId);
                    handleSearchUpdateClassesByFilter(
                      listTempClass,
                      teacherId,
                      selectedDepartment
                    );
                  }}
                >
                  {listTeacher.map((teacher) => {
                    return (
                      <Option
                        key={teacher.id}
                        value={teacher.id}
                        label={teacher?.firstName + " " + teacher?.lastName}
                      >
                        {teacher?.firstName + " " + teacher?.lastName}
                      </Option>
                    );
                  })}
                </Select>,
                <Select
                  allowClear
                  style={{ width: "150px" }}
                  placeholder="Tìm theo cơ sở"
                  onChange={(e) => {
                    setSelectedDepartment(e);
                  }}
                  onClear={() => {
                    setSelectedDepartment(null);
                    setListClass(listTempClass);
                  }}
                  onSelect={(department: any) => {
                    setSelectedDepartment(department);
                    handleSearchUpdateClassesByFilter(
                      listTempClass,
                      selectedTeacherId,
                      department
                    );
                  }}
                >
                  {listDepartmentOption.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>,

                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsModalCreateVisible(true)}
                >
                  {intl.formatMessage({
                    id: "add",
                  })}
                </Button>,
              ]}
            />
          </Card>
        </Col>
      </Row>
      {isModalCreateVisible && (
        <ClassCreateForm
          visible={isModalCreateVisible}
          onCreate={handleCreateClass}
          onCancel={() => setIsModalCreateVisible(false)}
          teachers={listTeacher.map((teacher) => {
            return {
              id: teacher.id,
              name: teacher?.firstName + " " + teacher?.lastName,
            };
          })}
        />
      )}
      {isModalEditVisible && (
        <ClassEditForm
          visible={isModalEditVisible}
          value={selectedClass}
          onConfirm={handleConfirmEdit}
          onCancel={() => setIsModalEditVisible(false)}
          teachers={listTeacher.map((teacher) => {
            return {
              id: teacher.id,
              name: teacher?.firstName + " " + teacher?.lastName,
            };
          })}
        />
      )}
    </Fragment>
  );

  const classDetailTable = (
    <ClassDetailTable
      listClass={listTempClass}
      listTeacher={listTeacher}
      resetCnt={cnt}
    />
  );
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Danh sách lớp`,
      children: listClassComponent,
    },
    {
      key: "2",
      label: `Chi tiết lớp`,
      children: classDetailTable,
    },
  ];
  const [key, setKey] = useState("1");

  const onChange = (key: string) => {
    if (key == "2") {
      setCnt(cnt + 1);
    }
    setKey(key);
  };

  return (
    <Tabs
      type="card"
      activeKey={key}
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
    />
  );
};

export default withAuth(injectIntl(Class));
