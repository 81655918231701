import React, { Fragment, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import SchoolSearchCard from "../components/school/SchoolSearchCard";
import { Button, Card, Col, Row, message, notification } from "antd";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { SchoolInfo } from "../types/school";
import withAuth from "../components/auth/withAuth";
import { createSchool, getAllSchool, updateSchool } from "../services/school";
import SchoolCreateForm from "../components/school/SchoolCreateForm";
import { PlusOutlined } from "@ant-design/icons";
import SchoolEditForm from "../components/school/SchoolEditForm";

const School = (props: any) => {
  const { intl } = props;
  const [listSchool, setListSchool] = useState<SchoolInfo[]>([]);
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [editFormVisible, setEditFormVisible] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<SchoolInfo>();

  useEffect(() => {
    handleGetAllSchool();
  }, []);

  const handleGetAllSchool = () => {
    getAllSchool().then((res) => {
      setListSchool(res.data.items || []);
    });
  };

  const handleConfirmEdit = (record: SchoolInfo) => {
    updateSchool(record)
      .then(() => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.update.complete",
            defaultMessage: "Updated Complete",
          }),
          description: intl.formatMessage({
            id: "detail.update.school.successfully",
            defaultMessage: "detail.update.school.successfully",
          }),
        });
        handleGetAllSchool();
        setEditFormVisible(false);
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          notification.error({
            message: intl.formatMessage({
              id: "noti.update.fail",
              defaultMessage: "noti.update.fail",
            }),
            description: "Bạn không được cấp quyền này",
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: "noti.update.fail",
              defaultMessage: "noti.update.fail",
            }),
            description: intl.formatMessage({
              id: "detail.update.school.fail",
              defaultMessage: "detail.update.school.fail",
            }),
          });
        }
      });
  };
  const handleCreate = (values: { name: string }) => {
    createSchool(values.name)
      .then((res) => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.create.complete",
            defaultMessage: "noti.create.complete",
          }),
          description: intl.formatMessage({
            id: "detail.create.school.successfully",
            defaultMessage: "detail.create.school.successfully",
          }),
        });
        handleGetAllSchool();
        setCreateFormVisible(false);
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          notification.error({
            message: intl.formatMessage({
              id: "noti.create.fail",
              defaultMessage: "noti.create.fail",
            }),
            description: "Bạn không được cấp quyền này",
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: "noti.create.fail",
              defaultMessage: "noti.create.fail",
            }),
            description: intl.formatMessage({
              id: "detail.create.school.fail",
              defaultMessage: "detail.create.school.fail",
            }),
          });
        }
      });
  };

  const columns: ProColumns<SchoolInfo>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: intl.formatMessage({
        id: "school.name",
        defaultMessage: "School Name",
      }),
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      copyable: true,
      // tip: `School name`,
    },
    {
      title: intl.formatMessage({
        id: "action",
        defaultMessage: "Action",
      }),
      key: "action",
      render: (text: any, record: SchoolInfo) => (
        <Button
          style={{ height: "30px", lineHeight: "30px" }}
          type="primary"
          onClick={() => {
            setSelectedSchool(record);
            setEditFormVisible(true);
          }}
        >
          {intl.formatMessage({
            id: "edit",
            defaultMessage: "Edit",
          })}
        </Button>
      ),
    },
  ];

  const pagination = {
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} ${intl.formatMessage({
        id: "of",
      })} ${total} ${intl.formatMessage({ id: "records" })}`,
  };

  return (
    <Fragment>
      {/* <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <SchoolSearchCard></SchoolSearchCard>
        </Col>
      </Row> */}
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card>
            <ProTable
              headerTitle={intl.formatMessage({
                id: "school.list",
                defaultMessage: "School List",
              })}
              dataSource={listSchool}
              columns={columns}
              search={false}
              pagination={pagination}
              toolBarRender={() => [
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setCreateFormVisible(true)}
                >
                  {intl.formatMessage({
                    id: "add",
                    defaultMessage: "Add",
                  })}
                </Button>,
              ]}
            />
          </Card>
        </Col>
      </Row>
      {createFormVisible && (
        <SchoolCreateForm
          visible={createFormVisible}
          onCreate={handleCreate}
          onCancel={() => setCreateFormVisible(false)}
        />
      )}

      {editFormVisible && (
        <SchoolEditForm
          visible={editFormVisible}
          onConfirm={handleConfirmEdit}
          onCancel={() => setEditFormVisible(false)}
          value={selectedSchool}
        />
      )}
    </Fragment>
  );
};

export default withAuth(injectIntl(School));
