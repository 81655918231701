import React, { useEffect, useState } from "react";
import {
  StudentInfo,
  StudentInfoDetail,
  StudentTuitionDetail,
} from "../../types/student";
import { Form, Modal, Tabs, TabsProps } from "antd";
import { injectIntl } from "react-intl";
import {
  convertFromDateResponseToDateFormat,
  convertFromDateResponseToMoment,
} from "../utils";

import {
  getStudentInfoDetail,
  getStudentTuition,
} from "../../services/student";
import { ColumnsType } from "antd/lib/table";
import { ClassInfo } from "../../types/class";
import moment from "moment";
import StudentListTuition from "./StudentListTuition";
import { TeacherInfo } from "../../types/teacher";
import { getAllClass } from "../../services/classes";
import { getAllTeacher } from "../../services/teacher";

interface StudentTuitionDetailFormProps {
  intl: any;
  visible: boolean;
  value?: StudentInfo;
  // onConfirm: (values: StudentInfo) => void;
  onCancel: () => void;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StudentTuitionDetailForm: React.FC<StudentTuitionDetailFormProps> = ({
  visible,
  intl,
  onCancel,
  value,
}) => {
  const [form] = Form.useForm();
  const [studentInfoDetail, setStudentInfoDetail] =
    useState<StudentInfoDetail | null>(null);
  const [paidTuitions, setPaidTuitions] = useState<StudentTuitionDetail[]>([]);
  const [unpaidTuitions, setUnpaidTuitions] = useState<StudentTuitionDetail[]>(
    []
  );

  const [refreshCounter, setRefreshCounter] = useState(0);

  const [listTeacher, setListTeacher] = useState<TeacherInfo[]>([]);
  const [listClass, setListClass] = useState<ClassInfo[]>([]);
  const handleGetAllClass = () => {
    getAllClass().then((res) => {
      const listClass = res.data.items;
      setListClass(listClass || []);
    });
  };
  const handleGetAllTeacher = () => {
    getAllTeacher().then((res) => {
      setListTeacher(res.data.items || []);
    });
  };

  useEffect(() => {
    handleGetAllClass();
    handleGetAllTeacher();
  }, []);

  useEffect(() => {
    if (value && value.id) {
      getStudentInfoDetail(value.id).then((res) => {
        setStudentInfoDetail(res.data as StudentInfoDetail);
        handleGetStudentTuition(value.id);
      });
    }
  }, [value]);

  useEffect(() => {
    if (refreshCounter > 0) {
      if (value && value.id) {
        handleGetStudentTuition(value.id);
      }
    }
  }, [refreshCounter]);

  const handleGetStudentTuition = (studentId: number) => {
    getStudentTuition(studentId).then((res) => {
      setPaidTuitions(res.data.paidTuiTion);
      setUnpaidTuitions(res.data.unpaidTuiTion);
    });
  };

  useEffect(() => {
    if (studentInfoDetail) {
      form.setFieldValue(
        "createdAt",
        moment(studentInfoDetail.createdAt).format("DD/MM/YYYY HH:mm:ss")
      );
      form.setFieldValue(
        "updatedAt",
        moment(studentInfoDetail.updatedAt).format("DD/MM/YYYY HH:mm:ss")
      );
    }
  }, [form, studentInfoDetail]);

  useEffect(() => {
    form.setFieldValue("firstName", value?.firstName);
    form.setFieldValue("lastName", value?.lastName);
    form.setFieldValue(
      "dob",
      convertFromDateResponseToMoment(value?.dob || null)
    );
    form.setFieldValue("phoneNumber", value?.phoneNumber);
    form.setFieldValue("address", value?.address);
    form.setFieldValue("email", value?.email);
    form.setFieldValue("parentPhoneNumber", value?.parentPhoneNumber);
    form.setFieldValue("parentFullName", value?.parentFullName);
  }, [form, value]);

  const handleUpdateRefreshCounter = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Học Phí Chưa Thu`,
      children: (
        <StudentListTuition
          handleUpdateRefreshCounter={handleUpdateRefreshCounter}
          studentId={value?.id || -1}
          dataSource={unpaidTuitions}
          visible
          listClass={listClass}
          listTeacher={listTeacher}
          isUnpaid={true}
        />
      ),
    },
    {
      key: "2",
      label: `Học Phí Đã Thu`,
      children: (
        <StudentListTuition
          studentId={value?.id || -1}
          dataSource={paidTuitions}
          visible
          listClass={listClass}
          listTeacher={listTeacher}
          isUnpaid={false}
        />
      ),
    },
  ];
  const onTabChange = (key: string) => {
    // console.log(key);
  };

  return (
    <Modal
      width={1000}
      open={visible}
      title={`THÔNG TIN HỌC PHÍ:    ${value?.firstName || ""} ${
        value?.lastName || ""
      } - ${convertFromDateResponseToDateFormat(value?.dob || "")} `}
      footer={null} // Hide the Ok button
      cancelText={intl.formatMessage({
        id: "cancel",
        defaultMessage: "Cancel",
      })}
      onCancel={onCancel}
    >
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
    </Modal>
  );
};

export default injectIntl(StudentTuitionDetailForm);
