import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Radio, Space } from "antd";
import { injectIntl } from "react-intl";
import { ClassStudentDetail } from "../../types/class";
import { searchStudent } from "../../services/student";
import { convertFromDateResponseToDateFormat } from "../utils";
import { debounce } from "lodash";

interface SearchStudentModalProps {
  visible: boolean;
  existingStudent: ClassStudentDetail[];
  onClose: () => void;
  onSubmit: (selectedStudentId: number) => void;
  intl: any;
}

const SearchStudentModal: React.FC<SearchStudentModalProps> = ({
  visible,
  onClose,
  onSubmit,
  existingStudent,
  intl,
}) => {
  const [form] = Form.useForm();
  const [selectStudentForm] = Form.useForm();
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [searchResults, setSearchResults] = useState<
    { id: number; fullName: string; dob: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSearchResults([]);
  }, [existingStudent]);

  const handleSearch = debounce((values: { searchName: string }) => {
    setIsLoading(true);
    searchStudent({ name: values.searchName || "" }).then((res) => {
      // console.log("resssssss", res);
      let searchResults: { id: number; fullName: string; dob: string }[] = [];
      if (existingStudent) {
        searchResults = res.data.items.reduce((ret, item) => {
          const isItemExisted = existingStudent.find(
            (student) => student.id === item.id
          );
          if (!isItemExisted) {
            ret.push({
              id: item.id,
              fullName: item.firstName + " " + item.lastName,
              dob: item.dob,
            });
          }
          return ret;
        }, [] as { id: number; fullName: string; dob: string }[]);
      }
      setSearchResults(searchResults);
      setIsLoading(false);
    });
  }, 1000); // Adjust the debounce delay as needed

  const handleSubmit = () => {
    const selectedStudent = selectStudentForm.getFieldValue("selectedStudent");
    setSelectedStudentId(selectedStudent);
    onSubmit(selectedStudent);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title={intl.formatMessage({
        id: "add.student.to.class",
      })}
    >
      <Form layout="vertical" form={form} onFinish={handleSearch}>
        <Form.Item name="searchName">
          <Input
            placeholder="Search by student name"
            onPressEnter={() => form.submit()}
            onChange={() => form.submit()}
          />
        </Form.Item>
      </Form>
      {!isLoading ? (
        searchResults.length > 0 ? (
          <Form layout="vertical" form={selectStudentForm}>
            <Form.Item label="Chọn một học sinh" name="selectedStudent">
              <div style={{ height: "200px", overflowY: "scroll" }}>
                <Radio.Group name="student">
                  <Space direction="vertical">
                    {searchResults.map((student) => (
                      <Radio key={student.id} value={student.id}>
                        {`${
                          student.fullName
                        }  [${convertFromDateResponseToDateFormat(
                          student.dob
                        )}]`}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </div>
            </Form.Item>

            <Form.Item style={{ justifyContent: "flex-end" }}>
              <Button type="primary" onClick={handleSubmit}>
                Add
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div> Không có học sinh trùng khớp </div>
        )
      ) : (
        <div> Đang tải... </div>
      )}
    </Modal>
  );
};

export default injectIntl(SearchStudentModal);
