import React, { Fragment, useEffect, useState } from "react";
import { StudentTuitionDetail } from "../../types/student";
import { Button, Row, Select, Space, Table } from "antd";
import { injectIntl } from "react-intl";
import { filterOption } from "../utils";

import { ColumnsType } from "antd/lib/table";
import { ClassInfo } from "../../types/class";
import moment from "moment";
import { TeacherInfo } from "../../types/teacher";
import { DepartmentName } from "../../types/enum";
import StudentCreateTuitionModal from "./StudentCreateTuitionModal";

const { Option } = Select;

interface StudentListTuitionProps {
  intl: any;
  visible: boolean;
  dataSource: StudentTuitionDetail[];
  listClass: ClassInfo[];
  listTeacher: TeacherInfo[];
  isUnpaid: boolean;
  studentId: number;
  handleUpdateRefreshCounter?: () => void;
}

const StudentListTuition: React.FC<StudentListTuitionProps> = ({
  visible,
  intl,
  listClass,
  listTeacher,
  isUnpaid,
  dataSource,
  handleUpdateRefreshCounter,
  studentId,
}) => {
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);
  const [classSelectList, setClassSelectList] = useState<
    { id: number; name: string; teacherId: number; department: string }[]
  >([]);
  const [filteredDataSource, setFilteredDataSource] = useState<
    StudentTuitionDetail[]
  >([]);
  const [selectedTuition, setSelectedTuition] =
    useState<StudentTuitionDetail | null>(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleCancelEditModal = () => {
    setModalEditVisible(false);
    setSelectedTuition(null);
  };

  const handleFormSubmit = () => {
    setModalVisible(false);
  };

  const handleFormEditSubmit = () => {
    setSelectedTuition(null);
    setModalEditVisible(false);
  };

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (listClass) {
      let classSelectList = listClass.map((classItem) => {
        return {
          id: classItem.id,
          name: classItem.className,
          teacherId: classItem.teacher.id,
          department: DepartmentName[classItem.department],
        };
      });
      if (!selectedTeacherId) {
        setClassSelectList(classSelectList);
      } else {
        const newClassSelectList = classSelectList.filter(
          (classItem) => classItem.teacherId === selectedTeacherId
        );
        setClassSelectList(newClassSelectList);
      }
    }
  }, [listClass, selectedTeacherId]);

  useEffect(() => {
    setFilteredDataSource(
      handleFilteringTuition(selectedTeacherId, selectedClassId)
    );
  }, [selectedTeacherId, selectedClassId]);

  const handleFilteringTuition = (
    teacherId: number | null,
    classId: number | null
  ) => {
    if (teacherId) {
      if (classId) {
        return dataSource.filter(
          (tuition) =>
            tuition.teacherId === teacherId && tuition.classId === classId
        );
      } else {
        return dataSource.filter((tuition) => tuition.teacherId === teacherId);
      }
    }
    return dataSource;
  };

  let columns: ColumnsType<StudentTuitionDetail> = [
    {
      title: "Tên lớp",
      width: "100px",
      dataIndex: "className",
      ellipsis: true,
    },
    {
      title: "Tên GV",
      dataIndex: "teacherName",
      ellipsis: true,
    },
    {
      title: "Tháng",
      dataIndex: "month",
      ellipsis: true,
    },
    {
      title: "Năm",
      dataIndex: "year",
      ellipsis: true,
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      ellipsis: true,
      render: (amount, record, index) => {
        return `${amount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`;
      },
    },
    {
      title: "Sửa",
      render: (text: any, record: StudentTuitionDetail) => {
        if (isUnpaid === false) {
          return <></>;
        }
        const month = +moment().format("M");
        const year = +moment().format("Y");
        if (
          (record.month >= month && record.year >= year) ||
          (record.month < month && record.year > year)
        )
          return <></>;
        return (
          <Space>
            <Button
              style={{ height: "30px", lineHeight: "30px" }}
              type="primary"
              onClick={() => {
                setSelectedTuition(record);
                setModalEditVisible(true);
              }}
            >
              {intl.formatMessage({
                id: "edit",
              })}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Row>
        <Space>
          <Select
            style={{ width: "20rem" }}
            showSearch
            optionLabelProp="label"
            filterOption={filterOption}
            allowClear
            placeholder="Nhập giáo viên"
            onClear={() => {
              setSelectedTeacherId(null);
              setSelectedClassId(null);
            }}
            onSelect={(teacherId: any) => {
              setSelectedTeacherId(teacherId);
              setSelectedClassId(null);
            }}
          >
            {listTeacher.map((teacher) => {
              return (
                <Option
                  key={teacher.id}
                  value={teacher.id}
                  label={teacher.firstName + " " + teacher.lastName}
                >
                  {teacher.firstName + " " + teacher.lastName}
                </Option>
              );
            })}
          </Select>
          <Select
            style={{ width: "20rem" }}
            showSearch
            optionLabelProp="label"
            filterOption={filterOption}
            allowClear
            placeholder="Nhập tên lớp"
            onSelect={(classId: any) => {
              setSelectedClassId(classId);
            }}
            onClear={() => {
              setSelectedClassId(null);
            }}
          >
            {classSelectList.map((classItem) => {
              return (
                <Option
                  key={classItem.id}
                  value={classItem.id}
                  label={classItem.name}
                >
                  {classItem.name + ` [Cở Sở: ${classItem.department}]`}
                </Option>
              );
            })}
          </Select>
          {isUnpaid && (
            <Button onClick={handleShowModal} type="primary">
              Thêm
            </Button>
          )}
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredDataSource}
        rowKey="id"
        scroll={{ y: 200 }}
        size="small"
        pagination={false}
      />
      <StudentCreateTuitionModal
        studentId={studentId}
        listClass={listClass}
        listTeacher={listTeacher}
        visible={modalVisible}
        isEdited={false}
        onCancel={handleCancelModal}
        onSubmit={handleFormSubmit}
        handleUpdateRefreshCounter={handleUpdateRefreshCounter}
      />
      <StudentCreateTuitionModal
        studentId={studentId}
        selectedTuition={selectedTuition}
        listClass={listClass}
        listTeacher={listTeacher}
        visible={modalEditVisible}
        isEdited={true}
        onCancel={handleCancelEditModal}
        onSubmit={handleFormEditSubmit}
        handleUpdateRefreshCounter={handleUpdateRefreshCounter}
      />
    </Fragment>
  );
};

export default injectIntl(StudentListTuition);
