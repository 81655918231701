import { AxiosResponse } from "axios";
import axiosInstance from "../config/axiosConfig";
import {
  CreateTeacherDto,
  GetAllTeacherDto,
  UpdateTeacherDto,
} from "../types/teacher";

export const getAllTeacher = async (): Promise<
  AxiosResponse<GetAllTeacherDto>
> => {
  const response = await axiosInstance.get("/teachers");
  return response;
};

export const searchTeacher = async (dto: {
  name: string;
}): Promise<AxiosResponse<GetAllTeacherDto>> => {
  const response = await axiosInstance.post("/teachers/search", dto);
  return response;
};

export const createTeacher = async (
  data: CreateTeacherDto
): Promise<AxiosResponse<{ id: number }>> => {
  const response = await axiosInstance.post("/teachers", data);
  return response;
};

export const updateTeacher = async (dto: UpdateTeacherDto): Promise<void> => {
  await axiosInstance.patch(`/teachers/${dto.id}`, dto, {
    headers: { "Content-Type": "application/merge-patch+json" },
  });
};
