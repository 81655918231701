import { StudentTuitionDetail } from "./student";
import { DepartmentEnum, TransactionType } from "./transaction";

export enum TransactionStatus {
  DRAFT = "DRAFT",
  DONE = "DONE",
  REJECTED = "REJECTED",
}
export type BillInfo = {
  id: number;
  type: TransactionType;
  isValid: boolean;
  isPaid: boolean;
  isPrinted: boolean;
  status: TransactionStatus;
  detail: string;
  note: string;
  totalAmount: number;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  studentFullName?: string;
};

export type BillTeacherInfo = {
  id: number;
  fullName: string;
};

export type BillStudentInfo = {
  id: number;
  fullName: string;
};

export type BillInfoDetail = {
  id: number;
  status: TransactionStatus;
  type: TransactionType;
  detail: string;
  amount: number;
  note: string;
  teacher: BillTeacherInfo;
  student: BillStudentInfo;
  department: DepartmentEnum;
  tuitions: StudentTuitionDetail[];
};

export type GetAllBillDto = {
  items: BillInfo[];
};

export type SearchBillList = {
  hasNext: boolean;
  offset: number;
  limit: number;
  total: number;
  items: BillInfo[];
  totalAmount: number;
};

export type CreateBillDto = {
  type: TransactionType;
  studentId: number;
  teacherId: number;
  tuitionIds: number[];
  detail: string;
  note: string;
  amount: number | undefined;
  department: DepartmentEnum;
};

export type BillReportFilterDto = {
  department: DepartmentEnum;
  startDate: Date;
  endDate: Date;
  month: number;
};

export type BillUpdateStatusDto = {
  id: number;
  status: TransactionStatus;
};
