import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import axiosInstance from "../config/axiosConfig";

type LoginData = {
  username: string;
  password: string;
};

type LoginResponse = {
  // accessToken: string;
  // refreshToken: string;
  // expiresIn: number;
  token: string;
};

export const login = async (
  data: LoginData
): Promise<AxiosResponse<LoginResponse>> => {
  const response = await axiosInstance.post("/auth/login", data);
  const decodedToken = jwtDecode(response.data.token);
  // Save the tokens in local storage
  localStorage.setItem("token", response.data.token);
  // localStorage.setItem("accessToken", response.data.accessToken);
  // localStorage.setItem("refreshToken", response.data.refreshToken);
  return response;
};
