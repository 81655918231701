import { TeacherInfo } from "./teacher";
import { DepartmentEnum } from "./transaction";

export type ClassInfo = {
  id: number;
  grade: number;
  className: string;
  educationLevel: EducationLevel;
  teacher: TeacherInfo;
  tuition: number;
  department: DepartmentEnum;
  students?: ClassStudentDetail[];
  isOpen: boolean;
};

export type StudentClassInfo = {
  id: number;
  grade: number;
  className: string;
  educationLevel: EducationLevel;
  teacher: TeacherInfo;
  tuition: number;
  department: DepartmentEnum;
  students?: ClassStudentDetail[];
  isOpen: boolean;
  tuitionNote: string;
};

export enum EducationLevel {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  HIGH = "HIGH",
  OTHER = "OTHER",
}

export type ClassStudentDetail = {
  id: number;
  firstName: string;
  lastName: string;
  // fullName: string;
  dob: string;
  isFree: boolean;
  tuitionPercent: number;
};

export type CreateClassDto = {
  grade: number;
  className: string;
  educationLevel: EducationLevel;
  teacherId: number;
  tuition: number;
};

export type GetAllClassDto = {
  items: ClassInfo[];
};

export type UpdateClassDtoInterface = {
  grade?: number;
  className?: string;
  educationLevel?: EducationLevel;
  teacherId?: number;
  tuition?: number;
};

export type UpdateClassDto = UpdateClassDtoInterface & {
  id: number;
  isOpen?: boolean;
};

// export type ClassDetailInfo = ClassInfo & {
//   students: ClassStudentInfo[];
// };

// export type ClassStudentInfo = StudentInfo & {
//   isFree: boolean;
// };
