import { Fragment, useRef, useState } from "react";
import "../css/styles.css";
import {
  CreateStudentDto,
  StudentInfo,
  UpdateStudentDto,
} from "../types/student";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import {
  createStudent,
  deleteStudent,
  searchStudent,
  updateStudent,
} from "../services/student";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Tooltip,
  notification,
} from "antd";
import { injectIntl } from "react-intl";
import withAuth from "../components/auth/withAuth";
import StudentCreateForm from "../components/student/StudentCreateForm";
import { EyeTwoTone, PlusOutlined, SnippetsOutlined } from "@ant-design/icons";
import StudentEditForm from "../components/student/StudentEditForm";
import { convertFromDateResponseToDateFormat } from "../components/utils";
import StudentInfoDetailForm from "../components/student/StudentInfoDetailForm";
import StudentTuitionDetailForm from "../components/student/StudentTuitionDetailForm";
const { confirm } = Modal;

const Student = (props: any) => {
  const { intl } = props;
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalInfoVisible, setIsModalInfoVisible] = useState(false);
  const [isModalTuitionVisible, setIsModalTuitionVisible] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState<StudentInfo>();
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  const [searchName, setSearchName] = useState<string>();
  const actionRef = useRef<ActionType>();

  const handleCreateStudent = (values: CreateStudentDto) => {
    createStudent(values)
      .then((res) => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.create.complete",
          }),
          description: intl.formatMessage({
            id: "detail.create.student.successfully",
          }),
        });
        setRefreshCounter((prev) => prev + 1);
        setIsModalCreateVisible(false);
      })
      .catch((e) => {
        if (e.response.status === 409) {
          notification.error({
            message: intl.formatMessage({
              id: "noti.create.fail",
            }),
            description: "Học sinh này đã có trong danh sách",
            duration: 10,
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: "noti.create.fail",
            }),
            description: intl.formatMessage({
              id: "detail.create.student.fail",
            }),
          });
        }
      });
  };

  const handleConfirmEdit = (record: UpdateStudentDto) => {
    updateStudent(record)
      .then(() => {
        notification.success({
          message: intl.formatMessage({
            id: "noti.update.complete",
          }),
          description: intl.formatMessage({
            id: "detail.update.student.successfully",
          }),
        });
        setRefreshCounter((prev) => prev + 1);
        // handleGetAllStudent();
        setIsModalEditVisible(false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          notification.error({
            message: "Cập nhập thất bại",
            description: "Học sinh này đã có trong danh sách",
            duration: 10,
          });
        } else {
          notification.error({
            message: "Cập nhật thất bại",
            description: "Có lỗi trong quá trình cập nhật",
          });
        }
      });
  };

  const pagination = {
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} ${intl.formatMessage({
        id: "of",
      })} ${total} ${intl.formatMessage({ id: "records" })}`,
  };

  const onDeleteStudent = (student: StudentInfo) => {
    confirm({
      title: "Bạn có chắc chắn xoá học sinh này không?",
      okText: "Có",
      cancelText: "Không",
      onOk() {
        deleteStudent(student.id)
          .then(() => {
            notification.success({
              message: "Xoá thành công",
              description: "Đã xoá học sinh thành công",
              duration: 10,
            });
            setRefreshCounter((prev) => prev + 1);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              notification.error({
                message: "Xoá thất bại",
                description: "Học sinh này đã có trong danh sách lớp",
                duration: 10,
              });
            } else {
              notification.error({
                message: "Xoá thất bại",
                description: "Có lỗi trong quá trình xoá",
              });
            }
          });
      },
    });
  };

  const columns: ProColumns<StudentInfo>[] = [
    {
      hideInTable: true,
      title: "ID",
      dataIndex: "id",
      width: "3%",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "fullname" }),
      dataIndex: "fullName",
      ellipsis: true,
      width: "20%",
      sorter: true,

      render: (text: any, record: StudentInfo) => (
        <div>{`${record.firstName} ${record.lastName}`}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: "dob" }),
      dataIndex: "dob",
      ellipsis: true,
      render: (text: any, record: StudentInfo) => {
        const date = convertFromDateResponseToDateFormat(record.dob);
        return <div>{`${date}`}</div>;
      },
    },
    {
      title: intl.formatMessage({ id: "phoneNumber" }),
      dataIndex: "phoneNumber",
      width: "7%",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "address" }),
      dataIndex: "address",
      ellipsis: true,
      width: "20%",
      hideInTable: true,
    },

    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      ellipsis: true,
      hideInTable: true,
    },
    {
      title: intl.formatMessage({ id: "parent.fullname" }),
      dataIndex: "parentFullName",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "parent.phoneNumber" }),
      dataIndex: "parentPhoneNumber",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "action" }),
      key: "action",
      render: (text: any, record: any) => (
        <Space>
          <Button
            style={{ height: "30px", lineHeight: "30px" }}
            type="primary"
            onClick={() => {
              setSelectedStudent(record);
              setIsModalEditVisible(true);
            }}
          >
            {intl.formatMessage({
              id: "edit",
            })}
          </Button>
          <Button
            style={{
              height: "30px",
              lineHeight: "30px",
              backgroundColor: "red",
              color: "white",
            }}
            onClick={() => {
              onDeleteStudent(record);
            }}
          >
            Xoá
          </Button>
          <Tooltip placement="top" title="Thông tin học sinh">
            <Button
              type="text"
              // style={{ backgroundColor: "lightgray" }}
              className="custom-btn"
              onClick={() => {
                setSelectedStudent(record);
                setIsModalInfoVisible(true);
              }}
            >
              <EyeTwoTone
                style={{ fontSize: "15px", width: "15px", height: "15px" }}
              />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Thông tin học phí">
            <Button
              type="text"
              className="custom-btn"
              onClick={() => {
                setSelectedStudent(record);
                setIsModalTuitionVisible(true);
              }}
            >
              <SnippetsOutlined
                style={{ fontSize: "15px", width: "15px", height: "15px" }}
              />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    const uppercaseValue = value.toUpperCase();

    setSearchName(uppercaseValue);
  };

  return (
    <Fragment>
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card>
            <ProTable<
              StudentInfo,
              {
                name?: string;
                pageSize?: number;
                current?: number;
                refreshCounter: number;
              }
            >
              headerTitle={intl.formatMessage({
                id: "student.list",
              })}
              columns={columns}
              actionRef={actionRef}
              search={false}
              pagination={pagination}
              params={{ name: searchName, refreshCounter: refreshCounter }}
              request={async (params, sorter, filter) => {
                const { name, pageSize, current } = params;
                const { fullName } = sorter;

                if (current !== undefined && pageSize !== undefined) {
                  const valueNew = await searchStudent({
                    name: name || undefined,
                    offset: (current - 1) * pageSize,
                    limit: pageSize,
                    order: fullName === "descend" ? "DESC" : "ASC",
                  });

                  return {
                    data: valueNew.data.items,
                    total: valueNew.data.total,
                    success: true,
                  };
                }
                return {
                  data: [],
                  total: 0,
                  success: false,
                };
              }}
              rowKey="id"
              toolBarRender={() => [
                <Input.Search
                  className="search-input"
                  style={{ height: "40px !important" }}
                  allowClear
                  placeholder={"Tìm tên học sinh"}
                  onSearch={handleSearch}
                />,
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsModalCreateVisible(true)}
                >
                  {intl.formatMessage({
                    id: "add",
                    defaultMessage: "Add",
                  })}
                </Button>,
              ]}
            />
          </Card>
        </Col>
      </Row>
      {isModalCreateVisible && (
        <StudentCreateForm
          visible={isModalCreateVisible}
          onCreate={handleCreateStudent}
          onCancel={() => setIsModalCreateVisible(false)}
          // schools={listSchool}
        />
      )}
      {isModalEditVisible && (
        <StudentEditForm
          visible={isModalEditVisible}
          value={selectedStudent}
          onConfirm={handleConfirmEdit}
          onCancel={() => setIsModalEditVisible(false)}
        />
      )}
      {isModalInfoVisible && (
        <StudentInfoDetailForm
          visible={isModalInfoVisible}
          value={selectedStudent}
          // onConfirm={handleConfirmEdit}
          onCancel={() => setIsModalInfoVisible(false)}
        />
      )}
      {isModalTuitionVisible && (
        <StudentTuitionDetailForm
          visible={isModalTuitionVisible}
          value={selectedStudent}
          onCancel={() => setIsModalTuitionVisible(false)}
        />
      )}
    </Fragment>
  );
};

export default withAuth(injectIntl(Student));
