import React, { useEffect } from "react";
import {
  ClassInfo,
  CreateClassDto,
  EducationLevel,
  UpdateClassDto,
} from "../../types/class";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { injectIntl } from "react-intl";
import { DepartmentEnum } from "../../types/transaction";
import { departmentRegex, listDepartmentOption } from "../../utils/utils";

interface EditClassProps {
  intl: any;
  visible: boolean;
  value?: ClassInfo;
  onConfirm: (values: UpdateClassDto) => void;
  onCancel: () => void;
  teachers: { id: number; name: string }[];
}

const { Option } = Select;

const layout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 16 },
};

const ClassEditForm: React.FC<EditClassProps> = ({
  visible,
  onConfirm,
  onCancel,
  teachers,
  value,
  intl,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("grade", value?.grade);
    form.setFieldValue("className", value?.className);
    form.setFieldValue("educationLevel", value?.educationLevel);
    form.setFieldValue("department", value?.department);
    if (value?.teacher) form.setFieldValue("teacherId", value?.teacher.id);
    form.setFieldValue("tuition", value?.tuition);
  }, [form, value]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        onConfirm({ id: value?.id, ...values });
      })
      .catch((info) => {
        console.log(`Validate Failed: ${info}`);
      });
  };

  return (
    <Modal
      open={visible}
      title={intl.formatMessage({
        id: "edit.class",
        defaultMessage: "Edit class",
      })}
      okText={intl.formatMessage({
        id: "edit",
        defaultMessage: "Edit",
      })}
      cancelText={intl.formatMessage({
        id: "cancel",
        defaultMessage: "Cancel",
      })}
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Form form={form} {...layout} labelCol={{ flex: "150px" }} labelWrap>
        <Form.Item
          name="grade"
          label={intl.formatMessage({
            id: "grade",
          })}
          rules={[
            {
              required: true,
              message: "Please enter the grade of the class",
            },
            {
              type: "number",
              min: 1,
              max: 12,
              message: "Please enter a number between 1 and 12",
              transform: (value) => Number(value), // convert input to number before validation
            },
          ]}
        >
          <Input type="number" placeholder="1-12" />
        </Form.Item>

        <Form.Item
          name="className"
          label={intl.formatMessage({
            id: "className",
          })}
          rules={[
            {
              required: true,
              message: "Please enter the name of the class",
            },
            {
              max: 50,
              message: "Class name must be less than 50 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="educationLevel"
          label={intl.formatMessage({
            id: "educationLevel",
          })}
          rules={[
            { required: true, message: "Please select the education level" },
            {
              pattern: new RegExp(
                `(${EducationLevel.HIGH})|(${EducationLevel.OTHER})|(${EducationLevel.PRIMARY})|(${EducationLevel.SECONDARY})`
              ),
              message: "Please select a valid education level",
            },
          ]}
        >
          <Select>
            <Option value={EducationLevel.PRIMARY}>
              {intl.formatMessage({
                id: "school.primary",
              })}
            </Option>
            <Option value={EducationLevel.SECONDARY}>
              {intl.formatMessage({
                id: "school.secondary",
              })}
            </Option>
            <Option value={EducationLevel.HIGH}>
              {intl.formatMessage({
                id: "school.high",
              })}
            </Option>
            <Option value={EducationLevel.OTHER}>
              {intl.formatMessage({
                id: "school.other",
              })}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="department"
          label={intl.formatMessage({
            id: "department",
          })}
          rules={[
            { required: true, message: "Vui lòng điền tên cơ sở" },
            {
              pattern: new RegExp(departmentRegex),
              message: "Please select a valid education level",
            },
          ]}
        >
          <Select>
            {listDepartmentOption.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="teacherId"
          label={intl.formatMessage({
            id: "teacher",
          })}
          rules={[
            {
              type: "number",
              message: "Teacher ID must be a number",
            },
          ]}
        >
          <Select disabled placeholder="Select teacher" allowClear>
            {teachers.map((teacher) => (
              <Select.Option key={teacher.id} value={teacher.id}>
                {teacher.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="tuition"
          label={intl.formatMessage({
            id: "tuition",
          })}
          rules={[
            {
              required: true,
              message: "Please enter the tuition amount",
            },
            {
              type: "number",
              message: "Tuition must be a number",
              transform: (value) => Number(value), // convert input to number before validation
            },
          ]}
        >
          <InputNumber
            placeholder="Enter tuition amount"
            style={{ width: "100%" }}
            formatter={(value) =>
              value
                ? `${value
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                : ""
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "") || ""}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default injectIntl(ClassEditForm);
