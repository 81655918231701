import React, { useEffect, useRef, useState } from "react";
import { StudentInfo, StudentInfoDetail } from "../../types/student";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputRef,
  Modal,
  Table,
  notification,
} from "antd";
import { CreateStudentDto } from "../../types/student";
import { injectIntl } from "react-intl";
import {
  DATE_FORMAT,
  DATE_REQUEST_FORMAT,
  convertFromDateResponseToMoment,
  handleInputUppercase,
} from "../utils";

import { getStudentInfoDetail } from "../../services/student";
import { ColumnsType } from "antd/lib/table";
import { ClassInfo, StudentClassInfo } from "../../types/class";
import moment from "moment";

interface StudentInfoDetailFormProps {
  intl: any;
  visible: boolean;
  value?: StudentInfo;
  // onConfirm: (values: StudentInfo) => void;
  onCancel: () => void;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StudentInfoDetailForm: React.FC<StudentInfoDetailFormProps> = ({
  visible,
  intl,
  // onConfirm,
  onCancel,
  value,
}) => {
  const [form] = Form.useForm();
  const [studentInfoDetail, setStudentInfoDetail] =
    useState<StudentInfoDetail | null>(null);

  useEffect(() => {
    if (value && value.id) {
      getStudentInfoDetail(value.id).then((res) => {
        setStudentInfoDetail(res.data as StudentInfoDetail);
      });
    }
  }, [value]);

  useEffect(() => {
    if (studentInfoDetail) {
      form.setFieldValue(
        "createdAt",
        moment(studentInfoDetail.createdAt).format("DD/MM/YYYY HH:mm:ss")
      );
      form.setFieldValue(
        "updatedAt",
        moment(studentInfoDetail.updatedAt).format("DD/MM/YYYY HH:mm:ss")
      );
    }
  }, [form, studentInfoDetail]);

  useEffect(() => {
    form.setFieldValue("firstName", value?.firstName);
    form.setFieldValue("lastName", value?.lastName);
    form.setFieldValue(
      "dob",
      convertFromDateResponseToMoment(value?.dob || null)
    );
    form.setFieldValue("phoneNumber", value?.phoneNumber);
    form.setFieldValue("address", value?.address);
    form.setFieldValue("email", value?.email);
    form.setFieldValue("parentPhoneNumber", value?.parentPhoneNumber);
    form.setFieldValue("parentFullName", value?.parentFullName);
  }, [form, value]);

  const columns: ColumnsType<StudentClassInfo> = [
    {
      title: "Tên lớp",
      width: "100px",
      dataIndex: "className",
      ellipsis: true,
    },
    {
      title: "Tên GV",
      dataIndex: "",
      ellipsis: true,
      render: (text: any, record: StudentClassInfo) => {
        const teacher = record.teacher;
        return (
          <div>
            {teacher.firstName} {teacher.lastName}
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "",
      width: "100px",
      ellipsis: true,
      render: (text: any, record: StudentClassInfo) => {
        const isOpen = record.isOpen;
        if (isOpen) return <div>Đang mở</div>;
        else return <div>Đã đóng</div>;
      },
    },
    {
      title: "Học Phí",
      dataIndex: "tuition",
      width: "150px",
      ellipsis: true,
      render: (amount, record, index) => {
        return `${amount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} VND`;
      },
    },
    {
      title: "Ghi Chú",
      dataIndex: "tuitionNote",
      ellipsis: true,
    },
  ];

  return (
    <Modal
      width={1000}
      open={visible}
      title={`Thông tin học sinh`}
      // okText={intl.formatMessage({
      //   id: "edit",
      //   defaultMessage: "Edit",
      // })}
      // okText="Lưu"'
      footer={null} // Hide the Ok button
      cancelText={intl.formatMessage({
        id: "cancel",
        defaultMessage: "Cancel",
      })}
      onCancel={onCancel}
      // onOk={onFinish}
    >
      <Form form={form} {...layout} labelCol={{ flex: "200px" }} labelWrap>
        <Form.Item
          name="firstName"
          label={intl.formatMessage({
            id: "firstName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Please input the student's first name",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} disabled />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={intl.formatMessage({
            id: "lastName",
          })}
          rules={[
            { required: true, message: "Please enter last name" },
            {
              max: 255,
              message: "Please input the student's last name",
            },
          ]}
        >
          <Input onInput={handleInputUppercase} disabled />
        </Form.Item>
        <Form.Item
          name="dob"
          label={intl.formatMessage({
            id: "dob",
          })}
          rules={[
            {
              required: true,
              message: "Please input the student's date of birth.",
            },
          ]}
        >
          <DatePicker format={DATE_FORMAT} disabled />
        </Form.Item>

        <Form.Item
          name="parentPhoneNumber"
          label={intl.formatMessage({
            id: "parent.phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Please input a valid phone number.",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label={intl.formatMessage({
            id: "phoneNumber",
          })}
          rules={[
            {
              pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
              message: "Please input a valid phone number.",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item name="createdAt" label="Ngày tạo">
          <Input disabled />
        </Form.Item>

        <Form.Item name="updatedAt" label="Ngày cập nhật">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Danh sách lớp">
          <Table
            columns={columns}
            dataSource={studentInfoDetail?.classes}
            // rowSelection={isEdited === false ? rowSelection : undefined}
            rowKey="id"
            scroll={{ y: 200 }}
            size="small"
            pagination={false}
          />
        </Form.Item>

        {/* <Form.Item
          name="address"
          label={intl.formatMessage({
            id: "address",
          })}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: "email",
          })}
          rules={[{ type: "email", message: "Please input a valid email." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="parentFullName"
          label={intl.formatMessage({
            id: "parent.fullname",
          })}
        >
          <Input onInput={handleInputUppercase} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default injectIntl(StudentInfoDetailForm);
