import { create } from "zustand";
import { DepartmentEnum } from "../types/transaction";
interface MainState {
  locale: string;
  billFilter: any;
  userInfo: any;
  departments: DepartmentEnum[];
  changeLocale: (by: string) => void;
  changeBillFilter: (newFilter: any) => void;
  changeUserInfo: (newUserInfo: any) => void;
  changeDepartments: (newDepartments: DepartmentEnum[]) => void;
}

export const useMainStore = create<MainState>()((set) => ({
  userInfo: {},
  locale: "vi",
  departments: [],
  billFilter: {},
  changeLocale: (by) => set((state) => ({ locale: by })),
  changeBillFilter: (newFilter) => set((state) => ({ billFilter: newFilter })),
  changeUserInfo: (newUserInfo) => set((state) => ({ userInfo: newUserInfo })),
  changeDepartments: (newDepartments) =>
    set((state) => ({ departments: newDepartments })),
}));
