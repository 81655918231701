import { AxiosResponse } from "axios";
import axiosInstance from "../config/axiosConfig";

export type UserInfoResDto = {
  id: number;
  name: string;
};

export type FindAllUserRes = {
  users: UserInfoResDto[];
};

export const getAllUsers = async (): Promise<AxiosResponse<FindAllUserRes>> => {
  const response = await axiosInstance.get("/users");
  return response;
};
