import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CreateTransactionDto, TransactionInfo } from "../types/transaction";
import { Button, Card, Col, Row, Tabs, TabsProps } from "antd";
// import { getAllTransaction } from "../services/transaction";
import withAuth from "../components/auth/withAuth";
import { injectIntl } from "react-intl";
import TransactionCreateForm from "../components/transaction/TransactionCreateForm";
import { PlusOutlined } from "@ant-design/icons";
import BillDetail from "../components/bill/billDetail/BillDetail";
import BillList from "../components/bill/listBill/BillList";
import BillTuition from "../components/bill/tuitionFilter/BillTuition";

const Transaction = (props: any) => {
  const { intl } = props;
  const handleEdit = (record: any) => {
    console.log("edit this record", record);
  };

  const [listTransaction, setListTransaction] = useState<TransactionInfo[]>([]);
  const actionRef = useRef<ActionType>();
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const columns: ProColumns<TransactionInfo>[] = [
    {
      title: "ID",
      hideInTable: true,
      dataIndex: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      title: "Student",
      dataIndex: "student.name",
    },
    {
      title: "Class",
      dataIndex: "class.className",
    },
    {
      title: "Teacher",
      dataIndex: "teacher.name",
    },
    {
      title: "Month",
      dataIndex: "month",
    },
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Is Debt",
      dataIndex: "isDebt",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button
          style={{ height: "30px", lineHeight: "30px" }}
          type="primary"
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  // const handleEdit = (record: any) => {
  //   console.log("edit this record", record);
  // };

  // const handleCreateTransaction = (values: CreateTransactionDto) => {
  //   console.log("Received values of form: ", values);
  //   setIsModalCreateVisible(false);
  //   // createStudent(values).then((res) => {
  //   //   handleGetAllStudent();
  //   //   setIsModalCreateVisible(false);
  //   // });
  // };

  // useEffect(() => {
  //   handleGetAllTransaction();
  // }, []);

  // const handleGetAllTransaction = () => {
  //   getAllTransaction().then((res) => {
  //     const listTransaction = res.data.items;
  //     setListTransaction(listTransaction || []);
  //   });
  // };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Danh Sách Hoá Đơn`,
      children: <BillList />,
    },
    {
      key: "2",
      label: `Báo Cáo`,
      children: <BillDetail />,
    },
    // {
    //   key: "3",
    //   label: `Tra Cứu Học Phí`,
    //   children: <BillTuition />,
    // },
  ];
  const [key, setKey] = useState("1");

  const onChange = (key: string) => {
    setKey(key);
  };

  return (
    <Tabs
      type="card"
      activeKey={key}
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
    />
  );
};

export default withAuth(injectIntl(Transaction));
